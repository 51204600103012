import { IlDownload, IlInfoRectangle } from '@mc/react-icons/il';
import { Chip, Stack } from '@mui/material';
import { unreachable } from '@rsdk/common';
import { useMemo } from 'react';

import { HostReportStatus, HostReportType } from 'shared/api/__generated__';
import BugGradient from 'shared/assets/icons/bug-gradient.svg?react';
import FileDownGradient from 'shared/assets/icons/file-text_down-gradient.svg?react';
import { formatDate } from 'shared/lib/utils/dates';
import { downloadFileByLink } from 'shared/lib/utils/downloadFileByLink';
import { Button } from 'shared/ui/buttons/Button';
import { Typography } from 'shared/ui/contents/Typography';
import { IconWrapper } from 'shared/ui/helpers/IconWrapper';
import { Tooltip } from 'shared/ui/helpers/Tooltip';

import { THostReport } from '../../api/hostCrashlyticsApi.types';
import cls from './hostReportItem.module.scss';
import {
  Background,
  ChipStatusData,
  ChipStyle,
  Color,
  Title,
} from './type/hostReportItemType';
import { HostTooltipContent } from './ui/HostTooltipContent/HostTooltipContent';

const chipStatusData: Record<HostReportStatus, ChipStatusData> = {
  PENDING: {
    color: Color.YELLOW,
    background: Background.YELLOW,
    title: Title.PENDING,
  },
  LOADED: {
    color: Color.GREEN,
    background: Background.GREEN,
    title: Title.LOADED,
  },
  LOAD_ERROR: {
    color: Color.PINK,
    background: Background.PINK,
    title: Title.LOAD_ERROR,
  },
};

const NameReportType = ({ type }: { type: HostReportType }): JSX.Element => {
  switch (type) {
    case HostReportType.LOG:
      return (
        <Stack direction='row' gap='5px' alignItems='center'>
          <IconWrapper Svg={FileDownGradient} />
          <Typography variant='h4'>Лог-файл</Typography>
        </Stack>
      );
    case HostReportType.CRASH_REPORT:
      return (
        <Stack direction='row' gap='5px' alignItems='center'>
          <IconWrapper Svg={BugGradient} />
          <Typography variant='h4'>Отчет об ошибке</Typography>
        </Stack>
      );
    default:
      return unreachable(type);
  }
};

export const HostReportItem = (props: { item: THostReport }) => {
  const { item } = props;
  const {
    status,
    url,
    requestedBy,
    requestedAt,
    type,
    uploadedAt,
    size,
    collectedAt,
  } = item;

  const chipStyle: ChipStyle = {
    minHeight: '34px',
    color: chipStatusData[status].color,
    background: chipStatusData[status].background,
    borderRadius: '8px',
    padding: '8px 4px',
    cursor: 'default',
  };

  const requestedByName = useMemo(() => {
    if (!requestedBy) return '';

    switch (requestedBy.__typename) {
      case 'Person':
        return (() => {
          const { personalData } = requestedBy;
          const { surname, name, patronymic } = personalData;
          const _name = name ? ` ${name.charAt(0)}.` : '';
          const _patronymic = patronymic ? ` ${patronymic.charAt(0)}.` : '';
          const shortName = `${surname}${_name}${_patronymic}`;
          return shortName;
        })();
      case 'Bot':
      case 'System':
        return requestedBy.shortName;
      case 'AnyAccountPreview':
        return requestedBy.name;
      default:
        return unreachable(requestedBy);
    }
  }, [requestedBy]);

  return (
    <Stack className={cls.Container}>
      <Stack gap='3px'>
        <Stack direction='row' gap='0.5rem'>
          <Typography variant='t3' color='gray'>
            {collectedAt ? formatDate(uploadedAt) : 'Нет данных'}
          </Typography>
          <Tooltip
            title={HostTooltipContent({
              fetchDate: formatDate(requestedAt),
              createDate: formatDate(collectedAt),
              status,
            })}
            placement='bottom'
          >
            <Stack>
              <IconWrapper
                Svg={IlInfoRectangle}
                style={{
                  color:
                    status === 'LOAD_ERROR'
                      ? 'var(--color-pink-200)'
                      : undefined,
                }}
                className={cls.TooltipIcon}
              />
            </Stack>
          </Tooltip>
        </Stack>
        <Typography sx={{ marginLeft: '-4px' }} variant='h4'>
          <NameReportType type={type} />
        </Typography>
        <Stack flexDirection='row' gap='.5rem'>
          <Typography variant='t3' color='gray'>
            {`${size?.kilobytes()} кб`}
          </Typography>
          <Typography variant='t3' color='gray'>
            {requestedByName}
          </Typography>
        </Stack>
      </Stack>
      <Stack className={cls.Options}>
        <Chip label={chipStatusData[status].title} style={chipStyle} />
        <Button
          disabled={status !== 'LOADED'}
          variant='outlined'
          size='small'
          className={cls.DownloadBtn}
          onClick={async () => {
            if (url) {
              try {
                await downloadFileByLink(String(url));
              } catch (err) {
                console.error('Не удалось скачать файл', err);
              }
            }
          }}
        >
          <IconWrapper Svg={IlDownload} />
        </Button>
      </Stack>
    </Stack>
  );
};
