import { Stack } from '@mui/material';
import type { Certificate } from 'shared/lib/external/cryptoPro';

import { classNames } from 'shared/lib/utils/classNames';
import { Typography } from 'shared/ui/contents/Typography';
import { Radio } from 'shared/ui/form/radios/Radio';
import { Tooltip } from 'shared/ui/helpers/Tooltip';

import { getValidTill, parseSubject, TokenValidStatus } from '../lib/utils';
import cls from './CertificateSelectModal.module.scss';

interface CertificateCardProps {
  certificate: Certificate;
  onChange: (val: Nullable<Certificate>) => void;
  value: Nullable<Certificate>;
  isDisabled?: boolean;
}

export const CertificateCard = (props: CertificateCardProps) => {
  const { certificate, onChange, value, isDisabled } = props;

  const subject = parseSubject(certificate.subjectName);
  const validity = getValidTill(certificate.validTo);
  const isChecked = value?.thumbprint === certificate.thumbprint;

  const card = (
    <Stack
      key={certificate.thumbprint}
      className={classNames(cls.Card, {
        [cls.CardChecked]: isChecked,
        [cls.CardDisabled]: isDisabled,
      })}
      onClick={() => {
        if (isDisabled) return;
        onChange(certificate);
      }}
      data-testid='certificateCard'
    >
      <Radio
        checked={value?.thumbprint === certificate.thumbprint}
        disabled={isDisabled || validity.status === TokenValidStatus.INVALID}
        value={value?.thumbprint}
        onChange={() => {
          onChange(certificate);
        }}
      />
      <Stack gap='.125rem'>
        <Typography
          className={
            isDisabled || validity.status === TokenValidStatus.INVALID
              ? cls.DisabledText
              : undefined
          }
          variant='t1'
        >{`${subject?.SN ?? ''} ${subject?.G ?? ''}`}</Typography>

        <Stack direction='row' gap='1rem'>
          <Typography
            variant='t3'
            className={
              !isDisabled && validity.status !== TokenValidStatus.INVALID
                ? cls.GreyText
                : cls.DisabledText
            }
          >
            {subject?.CN ?? ''}
          </Typography>
          <Typography
            variant='t3'
            className={
              !isDisabled && validity.status !== TokenValidStatus.INVALID
                ? cls.GreyText
                : cls.DisabledText
            }
          >
            {certificate.thumbprint}
          </Typography>
        </Stack>

        <Typography
          variant='t3'
          className={
            !isDisabled
              ? cls[
                  `${validity.status[0].toUpperCase()}${validity.status.slice(
                    1,
                  )}`
                ]
              : cls.DisabledText
          }
        >
          {validity.message}
        </Typography>
      </Stack>
    </Stack>
  );

  return isDisabled ? (
    <Tooltip title='ФИО на сертификате не совпадает с ФИО текущего медработника'>
      {card}
    </Tooltip>
  ) : (
    card
  );
};
