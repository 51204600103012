import { createSelector } from '@reduxjs/toolkit';

import { AccountType } from 'shared/api/__generated__';
import { TPermissionKey } from 'shared/api/authApi/types';

import { IGetRolePermissions } from './getRolePermissions.types';

const getUser = (state: RootState) => state?.user || {};

export const getRolePermissions = createSelector(getUser, (user) => {
  const { type, permissions } = user.role || {};

  /** Список разрешений пользователя из запроса */
  const permissionsKeys = permissions.map((el) => el.key);

  const list = new Set<TPermissionKey>([...permissionsKeys]);

  const listWithDescriptions = permissions;

  const result: IGetRolePermissions = {
    list,
    listWithDescriptions,
    has: (permission: TPermissionKey) => {
      // для root доступны все разрешения
      if (type === AccountType.ROOT) return true;
      // для тестового пользователя тестовые сущности доступны по-умолчанию
      if (permission === 'subtrees.test' && user.owner.isTest) {
        return true;
      }
      return list.has(permission);
    },
  };

  return result;
});
