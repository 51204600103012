import { Person } from 'shared/api/__generated__';
import { UserGetFilterOptionsQuery } from 'shared/api/__generated__/graphql';
import { PLURAL_PEOPLE_AGE } from 'shared/const/plural';
import { applyPlural } from 'shared/lib/utils/applyPlural';
import { formatDate } from 'shared/lib/utils/dates';
import { euDateFormat } from 'shared/lib/utils/dates/formatDate/formatDate';
import { nameFormat } from 'shared/lib/utils/nameFormat';

import { UserSelectItem } from '../types';

export const mapUserGetFilterOptionsToSelectItems = (
  accounts: UserGetFilterOptionsQuery['accounts'],
): UserSelectItem[] =>
  (
    (accounts.getFilteredList?.items.filter(
      (item) => item.__typename === 'Person',
    ) as Person[]) || []
  ).map((item) => {
    const { name, surname, patronymic, age, dob } = item.personalData;
    return {
      id: item.id,
      name: nameFormat(name, surname, patronymic),
      shortName: nameFormat(name, surname, patronymic, 'short'),
      dob: formatDate(dob, euDateFormat),
      age: `${age} ${applyPlural(age, PLURAL_PEOPLE_AGE)}`,
      accountType: item.type,
      status: item.status,
      ownerName: item.owner.displayShortname,
      currentPhotoUrl: item.currentPhoto?.url.href,
      role: {
        name: item.role.name,
      },
    };
  });
