import { ApolloError } from '@apollo/client';
import { IlArrowRight } from '@mc/react-icons/il';
import { Stack } from '@mui/system';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useIntersection } from 'shared/lib/hooks/useIntersection';
import { classNames } from 'shared/lib/utils/classNames';

import { Button } from '../../../buttons/Button';
import { IconButton } from '../../../buttons/IconButton';
import { IconWrapper } from '../../../helpers/IconWrapper';
import { Loader } from '../../../helpers/Loader';
import { Typography } from '../../Typography';

interface InfiniteListProps<TItem> {
  items: TItem[];
  renderItem: (item: TItem) => ReactNode;
  count: number;
  isFullLoaded: boolean;
  resultLoadingMessage?: ReactNode | string;
  pageSize?: number;
  className?: string;
  isLoading?: boolean;
  error?: ApolloError;
  scrollEndCallback?: () => void;
  emptyList?: ReactNode;
  dataTestId?: string;
}

const LoaderList = () => {
  const { t } = useTranslation();

  return (
    <Loader>
      {t('Loading list')}
      ...
    </Loader>
  );
};

export const InfiniteList = <TItem,>(props: InfiniteListProps<TItem>) => {
  const {
    items,
    renderItem,
    count,
    isFullLoaded,
    resultLoadingMessage,
    pageSize = 10,
    className,
    isLoading,
    error,
    scrollEndCallback,
    emptyList,
    dataTestId,
  } = props;

  const { t } = useTranslation();

  const { triggerRef } = useIntersection(() => {
    if (scrollEndCallback) scrollEndCallback();
  });

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 300);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const showMoreBtn = !isFullLoaded && !isLoading && (
    <Button variant='outlined' size='small' onClick={scrollEndCallback}>
      Показать ещё
    </Button>
  );

  if (error && !items.length) {
    return <div>{resultLoadingMessage || error.message}</div>;
  }

  if (!count && isLoading) {
    // TODO: заменить на скелетоны
    return <LoaderList />;
  }

  const currentPage = Math.round(count / pageSize);

  return (
    <>
      <Stack
        className={classNames('', {}, [className])}
        direction='column'
        gap='16px'
        width='100%'
        data-testid={dataTestId}
      >
        {count ? (
          <>
            {items.map(renderItem)}
            {isLoading && <LoaderList />}
            {/* Через каждые 5 загруженных страниц показывать кнопку "загрузить ещё" */}
            {currentPage > 1 && currentPage % 5 === 0 ? (
              !isFullLoaded && showMoreBtn
            ) : (
              <div ref={triggerRef} />
            )}
            {isFullLoaded && (
              <Typography
                textAlign='center'
                variant='t2'
                color='var(--color-monochrome-grey-400)'
              >
                Вы достигли конца списка
              </Typography>
            )}
          </>
        ) : (
          emptyList || (
            <Typography textAlign='center'>
              {resultLoadingMessage || t('No results found')}
            </Typography>
          )
        )}
      </Stack>
      {isScrolled && (
        <IconButton
          style={{
            position: 'fixed',
            bottom: '5rem',
            right: '5rem',
            width: '40px',
            height: '40px',
            transform: 'rotate(-90deg)',
            borderRadius: '.5rem',
            color: 'var(--color-blue-200)',
            background: 'var(--color-blue-000)',
          }}
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
        >
          <IconWrapper Svg={IlArrowRight} />
        </IconButton>
      )}
    </>
  );
};
