export const objectMap = <TIn, TOut>(
  map: (value: TIn) => TOut,
  obj: Record<string, TIn>,
): Record<string, TOut> => {
  return Object.entries(obj).reduce<Record<string, TOut>>(
    (acc, [key, value]) => {
      acc[key] = map(value);
      return acc;
    },
    {},
  );
};
