import { setContext } from '@sentry/react';

import { apolloClientWithoutAuth } from 'shared/api/_instances/clients/apolloClientWithoutAuth';
import { createStore } from 'shared/lib/external/zustand';
import { tErrors } from 'shared/lib/utils/getErrorMessage';

import { ServerInfoGet } from '../api/ServerInfoGet.gql';

type TServerInfoStore = {
  /** Время последнего обновления (время клиента) */
  lastUpdate?: Date;
  /** DateTime сервера полученное при последнем опросе сервера */
  serverTime?: Date;
  /**
   * Разница между временем клиента и сервера, ms.
   * NOTE: Если клиент опережает сервер, то clientOffset будет положительным.
   */
  clientOffset: number;
  /** Время выполнения запроса, ms */
  halfFetchTime?: number;
};

type TServerInfoEvents = {
  fetchServerTime: () => Promise<{ clientOffset: number }>;
};

export const useServerInfoStore = createStore<
  TServerInfoStore,
  TServerInfoEvents
>(
  'ServerInfo',
  {
    lastUpdate: undefined,
    serverTime: undefined,
    clientOffset: 0,
    halfFetchTime: undefined,
  },
  (set, get) => ({
    fetchServerTime: async () => {
      const start = new Date();
      const { data, errors } = await apolloClientWithoutAuth.mutate({
        mutation: ServerInfoGet,
      });
      const end = new Date();

      /**
       * Половина времени на выполнение запроса.
       * Грубо считаем что это время ответа от сервера до клиента.
       */
      const halfFetchTime = (end.getTime() - start.getTime()) / 2;

      /** Время сервера */
      const serverTime = data?.serverInfo.serverTime
        ? new Date(data?.serverInfo.serverTime) // FIXME: потом в скаляре будет возвращаться Date
        : undefined;

      if (errors) {
        console.error(`ServerInfoGet: ${tErrors(errors)}`);
      }
      if (!serverTime) {
        throw new Error('Не удалось получить время сервера');
      }

      /** Разница клиентского времени с серверным */
      const clientOffset = end.getTime() - halfFetchTime - serverTime.getTime();

      setContext('Client time', {
        client: end,
        server: serverTime,
        clientOffset,
        halfFetchTime,
      });

      set({
        lastUpdate: end,
        serverTime,
        clientOffset,
        halfFetchTime,
      });

      return { clientOffset };
    },
  }),
);
