import {
  AutocompleteInputChangeReason,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  FilterOptionsState,
  FormHelperText,
  Popper,
  PopperProps,
} from '@mui/material';
import React, {
  forwardRef,
  Fragment,
  KeyboardEvent,
  useCallback,
  useMemo,
} from 'react';

import { useThrottle } from 'shared/lib/hooks/useThrottle';

import { Loader } from '../../../helpers/Loader';
import { Autocomplete } from '../../selects/Autocomplete';
import { TextField } from '../TextField';
import { TUniversalAutocompleteRenderOption } from './types';
import cls from './UniversalAutocomplete.module.scss';
import { UniversalAutocompleteContext } from './UniversalAutocompleteContext';
import { VirtualizedListbox } from './VirtualizedListbox';

const CustomPopper = forwardRef<
  HTMLDivElement,
  PopperProps & { menuWidth?: number }
>((props, ref) => {
  const { anchorEl, menuWidth } = props;

  const defaultWidth = anchorEl
    ? (anchorEl as HTMLElement).clientWidth
    : 'auto';

  return (
    <Popper
      {...props}
      ref={ref}
      style={{ width: menuWidth || defaultWidth }}
      placement='bottom-start'
    />
  );
});

CustomPopper.displayName = 'CustomPaper';

export interface SelectOption {
  id: string;
  label: string;
}

export type AutocompleteValue<
  T,
  Multi extends boolean | undefined,
> = Multi extends true ? T[] : T | null;

interface UniversalAutocompleteMultipleProps<TOption> {
  /** Выбор нескольких значений */
  multiple: true;
  /** Если multiple: true, value должно быть массивом TOption */
  value: AutocompleteValue<TOption, true>;
  /** Обработчик изменения значения */
  onChangeValue: (value: AutocompleteValue<TOption, true>) => void;
}

interface UniversalAutocompleteSingleProps<TOption> {
  /** Выбор нескольких значений */
  multiple?: false;
  /** Если multiple: false, value должно быть TOption или null */
  value: AutocompleteValue<TOption, false>;
  /** Обработчик изменения значения */
  onChangeValue: (value: AutocompleteValue<TOption, false>) => void;
}

export type UniversalAutocompleteProps<
  TOption = SelectOption,
  TValue = string,
  Multi extends boolean | undefined = undefined,
> = {
  /** Элементы выпадающеего списка */
  options: TOption[];
  /** Обработчик события прокрутки до конца списка */
  loadMore?: () => void;
  /** Функция получения значения элемента списка */
  getOptionValue?: (option: TOption) => TValue;
  /** Функция получения текста для отображения выбранного элемента в текстовом поле при multiple === false. Используется также для получения текста элемента списка, если renderOption не определен */
  getOptionLabel?: (option: TOption) => string;
  /** Функция рендеринга элемента списка */
  renderOption?: TUniversalAutocompleteRenderOption<TOption, React.ReactNode>;
  /** Включает фильтрацию элементов списка. Используется нативная для MUI.Autocomplete фильтрация, или кастомная, если передать filterOptions */
  enableFilterOptions?: boolean;
  /** Кастомная функция фильтрации элементов списка. Используется если enableFilterOptions === true */
  filterOptions?: (
    options: TOption[],
    state: FilterOptionsState<TOption>,
  ) => TOption[];

  /** Поле неактивно, если true */
  disabled?: boolean;
  /** Отображается индикатор обязательного заполнения '*', если true */
  required?: boolean;
  /** В конце выпадающего списка отображается прелоадер, если true */
  isLoading?: boolean;
  /** В конце выпадающего списка отображается текст 'все элементы загружены', если true */
  isFullyLoaded?: boolean;
  /** Поле подсвечивается красным, если true */
  hasError?: boolean;

  /** Плейсхолдер текстового поля */
  placeholder?: string;
  /** Текст заголовка поля */
  label?: string;
  /** Текст под полем. Красного цвета, если hasError === true */
  helperText?: string;
  /** Ширина выпадающего списка */
  menuWidth?: number;

  /** Отключает ввод в текстовое поле, если true */
  disableSearch?: boolean;
  /** Значение текстового поля */
  search: string;
  /** Текст, который отображается в выпадающем списке, если массив элементов списка options пуст */
  searchErrorText?: string;
  /** Обработчик изменения значения текстового поля */
  onChangeSearch?: (value: string) => void;
  /** Текстовое поле очищается при закрытии выпадающего списка, если true */
  clearSearchOnClose?: boolean;

  /** Обработчик события открытия выпадающего списка */
  onOpenHandler?: () => void;
  /** Обработчик события закрытия выпадающего списка */
  onCloseHandler?: () => void;

  /** Удалять ли значения при нажатии backspace */
  deleteOnBackspace?: boolean;
  /** Отключает крестик для очистки */
  disableClearable?: boolean;
  /** Функция для вычисления неактивности элемента списка */
  getOptionDisabled?: (option: TOption) => boolean;

  /** Слоты выпадающего списка */
  slots?: {
    top?: React.ReactNode;
    bottom?: React.ReactNode;
  };

  dataTestId?: string;
} & TernaryUnion<
  Multi,
  UniversalAutocompleteMultipleProps<TOption>,
  UniversalAutocompleteSingleProps<TOption>
>;

export function UniversalAutocomplete<TOption, TValue>(
  props: UniversalAutocompleteProps<TOption, TValue, undefined>,
) {
  const {
    value,
    onChangeValue,

    options,
    loadMore,
    getOptionValue = (option: TOption) => (option as SelectOption).id,
    getOptionLabel = (option: TOption) => (option as SelectOption).label,

    disabled,
    required,
    multiple,
    isLoading,
    isFullyLoaded,

    placeholder,
    label,
    hasError,
    helperText,
    menuWidth,

    disableSearch,
    search,
    searchErrorText,
    onChangeSearch,
    clearSearchOnClose,

    onOpenHandler,
    onCloseHandler,

    renderOption: renderOptionExternal,

    deleteOnBackspace,
    disableClearable,
    getOptionDisabled,

    slots,

    dataTestId,
  } = props;

  /*
   * INPUT
   */
  const renderInput = useCallback(
    ({ inputProps, ...params }: AutocompleteRenderInputParams) => (
      <TextField
        required={required}
        error={multiple ? hasError && !!search : hasError}
        helperText={helperText}
        {...params}
        label={label}
        size='small'
        placeholder={placeholder ?? undefined}
        inputProps={{
          ...inputProps,
          'data-testid': dataTestId,
          readOnly: disableSearch,
        }}
        onKeyDown={(event: KeyboardEvent) => {
          if (
            !deleteOnBackspace &&
            (event.key === 'Backspace' || event.key === 'Delete')
          ) {
            event.stopPropagation();
          }
        }}
      />
    ),
    [
      search,
      required,
      hasError,
      helperText,
      label,
      placeholder,
      disableSearch,
      deleteOnBackspace,
    ],
  );

  const inputValue = useMemo(() => {
    if (disableSearch || !search) {
      return value && !multiple ? getOptionLabel(value) : '';
    }
    return search;
  }, [disableSearch, search, value, multiple, getOptionLabel]);

  const handleInputChange = useCallback(
    (
      _: React.SyntheticEvent,
      newInputValue: string,
      reason: AutocompleteInputChangeReason,
    ) => {
      // исключаем сброс по событию reset, чтобы поиск не сбрасывался при выборе элемента
      // по этой причине вычисление значения текстового поля происходит в useMemo inputValue (см. выше)
      if (reason !== 'reset') {
        onChangeSearch?.(newInputValue);
      }
    },
    [onChangeSearch],
  );

  /*
   * LIST ITEMS / OPTIONS
   */
  const renderOption = useCallback<
    TUniversalAutocompleteRenderOption<TOption, React.ReactNode>
  >(
    (optionProps, option, state) => {
      const key = `listItem-${getOptionValue?.(option)}`;

      return (
        <Fragment key={key}>
          {renderOptionExternal ? (
            renderOptionExternal(optionProps, option, state)
          ) : (
            <li {...optionProps}>{getOptionLabel?.(option)}</li>
          )}
        </Fragment>
      );
    },
    [getOptionValue, getOptionLabel, renderOptionExternal],
  );

  const isOptionEqualToValue = useCallback(
    (currentValue: TOption, el: TOption) => {
      const a = getOptionValue(currentValue);
      const b = getOptionValue(el);
      return a === b;
    },
    [getOptionValue],
  );

  /*
   * HANDLERS
   */
  const loadMoreWrapper = useCallback(() => {
    if (isFullyLoaded || !loadMore) return;
    loadMore();
  }, [isFullyLoaded, loadMore]);
  const loadMoreHandle = useThrottle(loadMoreWrapper, 500);

  const handleChangeSingle = useCallback(
    (_: React.SyntheticEvent, newValue: AutocompleteValue<TOption, false>) => {
      if (!multiple) {
        onChangeValue(newValue);
      }
    },
    [onChangeValue, multiple],
  );

  const handleChangeMultiple = useCallback(
    (_: React.SyntheticEvent, newValue: AutocompleteValue<TOption, true>) => {
      if (multiple) {
        onChangeValue(newValue);
      }
    },
    [onChangeValue, multiple],
  );

  const handleOpen = useCallback(() => {
    onOpenHandler?.();
  }, [onOpenHandler]);

  const handleClose = useCallback(() => {
    if (clearSearchOnClose) {
      onChangeSearch?.('');
    }
    onCloseHandler?.();
  }, [clearSearchOnClose, onChangeSearch, onCloseHandler]);

  /*
   * CONST
   */
  const renderTags = useCallback(() => null, []);

  const noOptionsText = useMemo(
    () =>
      searchErrorText ? (
        <FormHelperText error>{searchErrorText}</FormHelperText>
      ) : undefined,
    [searchErrorText],
  );

  const autocompleteProps = {
    // Состояние
    loading: isLoading,
    loadingText: <Loader />,
    noOptionsText,
    disabled,

    // Поиск
    renderInput,
    inputValue,
    onInputChange: handleInputChange,

    // Options
    options,
    isOptionEqualToValue,
    getOptionLabel,
    /**
     * NOTE: передаёт только параметры, сама отрисовка происходит
     * в ListboxComponent при этом Autocomplete считает,
     * что функция возвращает ReactNode, но по факту это не так
     */
    renderOption: (
      _props: React.HTMLAttributes<HTMLLIElement> & {
        key: any;
      },
      option: TOption,
      state: AutocompleteRenderOptionState,
    ) => [_props, option, state] as React.ReactNode,
    filterOptions: (x: TOption[]) => x,

    // Действия
    onOpen: handleOpen,
    onClose: handleClose,

    // Мульти
    renderTags,
    disableCloseOnSelect: multiple,
    disableClearable: multiple ? true : disableClearable,
    getOptionDisabled,
    classes: {
      option: cls.Option,
      listbox: cls.Listbox,
    },
    ListboxComponent: VirtualizedListbox,
  };

  /**
   * Параметры контекста для Autocomplete.
   *
   * @description Нужен, чтобы в VirtualizedListbox можно было вызывать
   * контекстные методы без ре-рендера.
   */
  const autocompleteCtxValue = useMemo(
    () => ({
      countItems: () => options.length,
      loadMore: loadMoreHandle,
      isFetching: () => !!isLoading,
      render: renderOption,
      slots,
    }),
    [options, loadMoreHandle, isLoading, renderOption, slots],
  );

  const popperComponent = useCallback(
    (popperProps: PopperProps) => (
      <CustomPopper {...popperProps} menuWidth={menuWidth} />
    ),
    [menuWidth],
  );

  return multiple ? (
    <UniversalAutocompleteContext.Provider value={autocompleteCtxValue}>
      <Autocomplete
        {...autocompleteProps}
        value={value}
        multiple
        onChange={handleChangeMultiple}
        ListboxComponent={VirtualizedListbox}
        PopperComponent={popperComponent}
      />
    </UniversalAutocompleteContext.Provider>
  ) : (
    <UniversalAutocompleteContext.Provider value={autocompleteCtxValue}>
      <Autocomplete
        {...autocompleteProps}
        value={value}
        onChange={handleChangeSingle}
        ListboxComponent={VirtualizedListbox}
        PopperComponent={popperComponent}
      />
    </UniversalAutocompleteContext.Provider>
  );
}
