import { IlClose as CloseIcon } from '@mc/react-icons/il';
import {
  Alert as MUIAlert,
  AlertProps as MUIAlertProps,
  AlertTitle,
  Box,
} from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { useNavigate } from 'react-router';

import { classNames } from 'shared/lib/utils/classNames';
import { Mods } from 'shared/lib/utils/classNames/classNames';

import { Button } from '../../../buttons/Button';
import { IconButton } from '../../../buttons/IconButton';
import { IconWrapper } from '../../IconWrapper';
import { getAlertClassName, getAlertIcon } from '../lib/helpers';
import { AlertTypes } from '../types';
import cls from './Alert.module.scss';

interface AlertProps extends MUIAlertProps {
  type: AlertTypes;
  title?: string;
  message?: string;
  onClose?: () => void;
  ref?: React.Ref<HTMLDivElement>;
  buttonInfo?: {
    /** Текст на кнопке уведомления */
    text: string;
  } & (
    | {
        navigatePath: string;
      }
    | {
        onClick: (() => void) | null;
      }
  );
}

export const Alert = (props: AlertProps) => {
  const { type, title, message, onClose, ref, buttonInfo, ...rest } = props;

  const navigate = useNavigate();

  const mods: Mods = {
    [cls.SuccessWrapper]: type === AlertTypes.SUCCESS,
    [cls.ErrorWrapper]: type === AlertTypes.ERROR,
    [cls.InfoWrapper]: type === AlertTypes.INFO,
  };

  return (
    <MUIAlert
      severity={type}
      data-testid={`${type}-alert`}
      onClose={onClose || undefined}
      ref={ref}
      className={getAlertClassName(type)}
      icon={false}
      action={
        onClose ? (
          <IconButton
            size='small'
            onClick={onClose}
            className={cls.CloseButton}
          >
            <IconWrapper Svg={CloseIcon} />
          </IconButton>
        ) : undefined
      }
      {...rest}
    >
      <Stack gap='.5rem'>
        <Stack flexDirection='row' gap='1rem'>
          <Stack justifyContent='center'>
            <IconWrapper
              Svg={getAlertIcon(type)}
              className={classNames(cls.IconWrapper, mods, [])}
            />
          </Stack>

          <Stack gap='0.25rem' maxWidth='100%'>
            {title && (
              <AlertTitle className={cls.TitleContainer}>{title}</AlertTitle>
            )}
            {message && (
              <Box
                // в текстах с бэка могут поступать длинные строки,
                // а это ломает отображение остального message
                sx={{
                  wordBreak: 'break-word',
                  maxWidth: '100%',
                }}
              >
                {message}
              </Box>
            )}
          </Stack>
        </Stack>

        {buttonInfo?.text && (
          <Stack marginLeft='3rem'>
            <Button
              data-testid='alertButton'
              style={{ minWidth: '300px' }}
              size='large'
              color={type === AlertTypes.ERROR ? 'error' : 'primary'}
              variant='contained'
              onClick={
                'navigatePath' in buttonInfo
                  ? () => navigate(buttonInfo.navigatePath)
                  : buttonInfo.onClick || undefined
              }
            >
              {buttonInfo.text}
            </Button>
          </Stack>
        )}
      </Stack>
    </MUIAlert>
  );
};

// Обернутый компонент для Snackbar
export const AlertWithRef = React.forwardRef<HTMLDivElement, AlertProps>(
  (props, ref) => <Alert ref={ref} {...props} />,
);
