import {
  IlAndroidSmartphone as PakIcon,
  IlBarChart as ReportIcon,
  IlHome1 as OrgIcon,
  IlLoader1 as ExamIcon,
  IlUserRectangle1 as MedCabinetIcon,
} from '@mc/react-icons/il';
import { createSelector } from '@reduxjs/toolkit';

import { getRolePermissions } from 'processes/user';

import { TPermissionKey } from 'shared/api/authApi/types';
import {
  ExamsRoutePaths,
  HostsRoutePaths,
  MedCabinetRoutePaths,
  OrgUnitsRoutePaths,
  ReportRoutePaths,
} from 'shared/const/router';

import { NavbarAccountsMenu } from '../../ui/NavbarAccountsMenu/NavbarAccountsMenu';
import { NavbarAdminpanelMenu } from '../../ui/NavbarAdminpanelMenu/NavbarAdminpanelMenu';
import { NavbarMenuList } from '../../ui/NavbarMenuItem/NavbarMenuItem.types';

export const getNavbarItems = createSelector(
  getRolePermissions,
  (permissions) => {
    const checkPermission = (
      permission: TPermissionKey | TPermissionKey[],
      item: NavbarMenuList[number],
    ) => {
      if (Array.isArray(permission)) {
        return permission.some((x) => permissions.has(x)) ? [item] : [];
      }
      return permissions.has(permission) ? [item] : [];
    };

    const navbarItems: NavbarMenuList = [
      // {
      //   path: GlobalRoutePaths.getRouteMain(),
      //   text: 'Меню',
      //   Icon: MenuIcon,
      // },
      // {
      //   path: GlobalRoutePaths.getRouteMain(),
      //   text: 'Дашборд',
      //   Icon: RequestIcon,
      // },
      ...checkPermission('exam.method.get_filtered_list_all', {
        path: ExamsRoutePaths.getRouteExams(),
        text: 'Осмотры',
        dataTestId: 'navbarExamsMenuItem',
        Icon: ExamIcon,
      }),
      ...checkPermission('host.method.get_filtered_list', {
        path: HostsRoutePaths.getRouteHosts(),
        text: 'ПАК',
        dataTestId: 'navbarHostsMenuItem',
        Icon: PakIcon,
      }),
      ...checkPermission('exam.method.resolve', {
        path: MedCabinetRoutePaths.getRouteMedCabinet(),
        text: 'Медкабинет',
        dataTestId: 'navbarMedCabinetMenuItem',
        Icon: MedCabinetIcon,
      }),
      ...checkPermission('org_unit.method.get_filtered_list', {
        path: OrgUnitsRoutePaths.getRouteOrgUnits(),
        text: 'Организации',
        dataTestId: 'navbarOrgUnitsMenuItem',
        Icon: OrgIcon,
      }),
      ...checkPermission(['report.method.get_filtered_list'], {
        path: ReportRoutePaths.getRouteReport(),
        text: 'Отчёты',
        dataTestId: 'navbarReportMenuItem',
        Icon: ReportIcon,
      }),
      ...checkPermission(
        [
          'account.method.get_filtered_list',
          'imports.method.get_filtered_list',
        ],
        <NavbarAccountsMenu />,
      ),
      ...checkPermission(
        [
          'role_management.method.get_permission_groups',
          'role_management.method.get_roles',
          'host_types.method.get_list',
          'admin_panel.audit.view',
        ],
        <NavbarAdminpanelMenu />,
      ),
    ];

    return navbarItems;
  },
);
