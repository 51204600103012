import { useLazyQuery } from '@apollo/client';
import {
  IlAndroidSmartphoneAttention,
  IlAndroidSmartphoneDelete,
  IlSmc,
} from '@mc/react-icons/il';
import { AutocompleteRenderOptionState, Stack } from '@mui/material';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getRolePermissions } from 'processes/user';

import { HostFilters, HostStatus } from 'shared/api/__generated__';
import { useErrorHandlerContext } from 'shared/lib/hooks/useErrorHandlerContext';
import { Tag } from 'shared/ui/contents/Tag';
import { Typography } from 'shared/ui/contents/Typography';
import { Checkbox } from 'shared/ui/form/checkboxes/Checkbox';
import {
  SearchSelectServer,
  SearchSelectServerProps,
} from 'shared/ui/form/selects/SearchSelectServer';
import { IconWrapper } from 'shared/ui/helpers/IconWrapper';
import { Tooltip } from 'shared/ui/helpers/Tooltip';

import { HOST_GET_FILTER_OPTIONS } from '../api/hostGetFilterOptions.gql';
import { mapHostGetFilterOptionsToSelectItems } from '../lib/mapHostGetFilterOptionsToSelectItems';
import { HostSelectItem } from '../types';
import cls from './HostSelect.module.scss';

type ExcludedSearchSelectServerProps =
  | 'getData'
  | 'getDataByValue'
  | 'getOptionLabel'
  | 'getOptionValue'
  | 'loading'
  | 'networkError'
  | 'renderOption';

export type HostSelectProps = (
  | Omit<
      SearchSelectServerProps<HostSelectItem, string, false>,
      ExcludedSearchSelectServerProps
    >
  | Omit<
      SearchSelectServerProps<HostSelectItem, string, true>,
      ExcludedSearchSelectServerProps
    >
) & {
  filters?: HostFilters;
};

export function HostSelect(props: HostSelectProps) {
  const {
    placeholder = 'Выберите ПАК',
    label = 'ПАК',
    pageSize = 10,
    selectedOptions,
    multiple,
    filters,
    value,
    onChange,
    ...restProps
  } = props;

  const permissions = useSelector(getRolePermissions);
  const canGetTest = permissions.has('subtrees.test');

  const errorHandlerContext = useErrorHandlerContext();

  const [getHosts, { loading, error: networkError }] = useLazyQuery(
    HOST_GET_FILTER_OPTIONS,
    {
      context: errorHandlerContext({
        message: 'Не удалось загрузить конфигурацию ПАК',
      }),
    },
  );

  const getData = useCallback(
    async (search: string, offset: number) => {
      try {
        const response = await getHosts({
          variables: {
            filters: {
              search,
              ...(!canGetTest ? { isTest: false } : {}),
              ...filters,
            },
            pagination: { limit: pageSize, offset },
          },
        });

        return {
          options: response.data?.hosts
            ? mapHostGetFilterOptionsToSelectItems(response.data?.hosts)
            : [],
          total: response.data?.hosts.getFilteredList?.total || 0,
        };
      } catch (error) {
        console.error(error);

        return {
          options: [],
          total: 0,
        };
      }
    },
    [getHosts, canGetTest, filters, pageSize],
  );

  const getDataByValue = useCallback(
    async (_value?: HostSelectItem['id'][]) => {
      try {
        const response = await getHosts({
          variables: {
            filters: {
              id: { in: _value },
              ...(!canGetTest ? { isTest: false } : {}),
              ...filters,
            },
            pagination: { limit: pageSize, offset: 0 },
          },
        });

        return {
          options: response.data?.hosts
            ? mapHostGetFilterOptionsToSelectItems(response.data?.hosts)
            : [],
        };
      } catch (error) {
        console.error(error);

        return {
          options: [],
        };
      }
    },
    [getHosts, canGetTest, filters, pageSize],
  );

  const getOptionLabel = useCallback(
    (option: HostSelectItem) => option.name || '',
    [],
  );

  const renderOption = useCallback(
    (
      optionProps: React.HTMLAttributes<HTMLLIElement>,
      option: HostSelectItem,
      state: AutocompleteRenderOptionState,
    ) => {
      const { selected, index } = state;
      const { id, legalId, owner, isMobile, status, verification } = option;

      const key = `listItem-${index}-${id}`;

      const isDisabled =
        status === HostStatus.DISABLED_DIRECTLY ||
        status === HostStatus.DISABLED_INDIRECTLY;

      return (
        <li {...optionProps} key={key}>
          {multiple && (
            <Checkbox
              className={cls.Checkbox}
              size='medium'
              checked={selected}
              id={key}
            />
          )}

          <Stack gap='4px'>
            <Stack
              gap='8px'
              flexDirection='row'
              alignItems='center'
              flexWrap='wrap'
            >
              <Typography variant='t1'>{getOptionLabel(option)}</Typography>

              {isMobile && (
                <Tooltip title='Мобильный'>
                  <Tag color='blue' icon label={<IconWrapper Svg={IlSmc} />} />
                </Tooltip>
              )}

              {!verification && (
                <Tooltip title='Не поверен'>
                  <Tag
                    color='yellow'
                    icon
                    label={<IconWrapper Svg={IlAndroidSmartphoneAttention} />}
                  />
                </Tooltip>
              )}

              {isDisabled && (
                <Tooltip title='Деактивирован'>
                  <Tag
                    color='lightpink'
                    icon
                    label={<IconWrapper Svg={IlAndroidSmartphoneDelete} />}
                  />
                </Tooltip>
              )}
            </Stack>

            <Stack
              rowGap='2px'
              columnGap='16px'
              flexDirection='row'
              flexWrap='wrap'
              color='var(--color-monochrome-grey-400)'
            >
              <Typography variant='t3'>{owner}</Typography>

              {legalId && <Typography variant='t3'>{legalId}</Typography>}
            </Stack>
          </Stack>
        </li>
      );
    },
    [getOptionLabel, multiple],
  );

  const searchSelectServerProps = {
    ...restProps,
    label,
    placeholder,
    isLoading: loading,
    getData,
    getDataByValue: selectedOptions ? undefined : getDataByValue,
    selectedOptions,
    networkError,
    getOptionLabel,
    renderOption,
  };

  return multiple ? (
    <SearchSelectServer
      {...searchSelectServerProps}
      multiple
      value={value}
      onChange={onChange}
    />
  ) : (
    <SearchSelectServer
      {...searchSelectServerProps}
      value={value}
      onChange={onChange}
    />
  );
}
