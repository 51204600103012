import { Stack } from '@mui/material';
import React, { forwardRef, ReactElement } from 'react';

import { classNames } from 'shared/lib/utils/classNames';

import cls from './Tag.module.scss';

type TagColors =
  | 'blue'
  | 'gradient'
  | 'green'
  | 'grey'
  | 'lightpink'
  | 'pink'
  | 'yellow';

export type TagProps = React.HTMLAttributes<HTMLDivElement> & {
  label: ReactElement;
  color: TagColors;
  icon?: boolean;
  className?: string;
};

// TODO: заменить .TestLabel по коду на этот компонент
export const Tag = forwardRef<HTMLDivElement, TagProps>((props, ref) => {
  const { label, color, icon = false, className, ...restProps } = props;

  const mods = {
    [cls.Green]: color === 'green',
    [cls.Yellow]: color === 'yellow',
    [cls.Pink]: color === 'pink',
    [cls.Gradient]: color === 'gradient',
    [cls.Grey]: color === 'grey',
    [cls.Lightpink]: color === 'lightpink',
    [cls.Blue]: color === 'blue',
  };

  return (
    <Stack
      {...restProps}
      ref={ref}
      direction='row'
      alignItems='center'
      className={classNames(cls.Tag, mods, [
        className,
        icon ? cls.TagIcon : cls.TagText,
      ])}
    >
      {label}
    </Stack>
  );
});

Tag.displayName = 'Tag';
