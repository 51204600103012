import { gql } from 'shared/api/__generated__';

export const hostCrashlyticsGetList = gql(/* GraphQL */ `
  query hostCrashlyticsGetList(
    $pagination: Pagination!
    $filters: HostReportsFilters!
  ) {
    crashlytics {
      getList(pagination: $pagination, filters: $filters) {
        items {
          id
          status
          type
          collectedAt
          uploadedAt
          size
          url
          requestedAt
          requestedBy {
            ... on Person {
              personalData {
                surname
                name
                patronymic
              }
            }
            ... on Bot {
              shortName: name
            }
            ... on System {
              shortName: name
            }
            ... on AnyAccountPreview {
              name
            }
          }
          period {
            from
            to
          }
        }
        total
      }
    }
  }
`);
