import {
  AddressScalar,
  AgeScalar,
  Base64Scalar,
  ChecksumAlgorithmScalar,
  ChecksumValueScalar,
  DateTimeScalar,
  DeliveryChannelScalar,
  DescriptionScalar,
  DeviceScalar,
  DriversLicenseScalar,
  EmailScalar,
  EntityNameScalar,
  HardwareIdScalar,
  KeyScalar,
  KPPScalar,
  LegalIdScalar,
  LoginScalar,
  MobilePhoneScalar,
  NothingScalar,
  OGRNIPScalar,
  OGRNScalar,
  OrgINNScalar,
  OTPScalar,
  PasswordScalar,
  PathScalar,
  PermissionKeyScalar,
  PersonalINNScalar,
  PersonNameScalar,
  PhoneScalar,
  PNScalar,
  PostalCodeScalar,
  RegExpScalar,
  SearchStringScalar,
  SecretScalar,
  SemanticVersionScalar,
  ShortEntityNameScalar,
  ShortStringScalar,
  SimpleDateScalar,
  SizeScalar,
  SNILSScalar,
  TimespanScalar,
  UploadScalar,
  URLScalar,
  UUIDScalar,
  XmlScalar,
} from '@mc/graphql-shared.scalars';

import { IScalarConfig } from './scalars.types';
import { objectMap } from './utils/objectMap';

/**
 * Единое место для определения импортов скаляров
 */
const imports = {
  Timespan: `import { Timespan } from '@rsdk/common'`,
  Size: `import { Size } from '@rsdk/common'`,
};

// TODO: Добавить в пакет gql-scalars файл codegen.config.yaml
// И доработать для применимости на бэке и фронте
export const scalarConfig: IScalarConfig = {
  // TODO: Включить после реализации всех скаляров
  strictScalars: false,

  globalImports: [],

  items: {
    Timespan: {
      typeInTs: 'Timespan',
      resolver: TimespanScalar,
      imports: [imports.Timespan],
    },
    Size: { typeInTs: 'Size', resolver: SizeScalar, imports: [imports.Size] },
    URL: { typeInTs: 'URL', resolver: URLScalar },
    SimpleDate: { typeInTs: 'Date', resolver: SimpleDateScalar },
    DateTime: { typeInTs: 'Date', resolver: DateTimeScalar },

    Age: { typeInTs: 'number', resolver: AgeScalar },

    Nothing: { typeInTs: 'object | null', resolver: NothingScalar },
    // JSON: { typeInTs: 'object', resolver: JsonScalar },
    JSON: { typeInTs: 'any' },
    Upload: { typeInTs: 'File', resolver: UploadScalar },

    ...objectMap((resolver) => ({ typeInTs: 'string', resolver }), {
      INN: undefined,
      SNILS: SNILSScalar,
      KPP: KPPScalar,
      PersonName: PersonNameScalar,
      Address: AddressScalar,
      Base64: Base64Scalar,
      Description: DescriptionScalar,
      DriversLicense: DriversLicenseScalar,
      Email: EmailScalar,
      EntityName: EntityNameScalar,
      Key: KeyScalar,
      Login: LoginScalar,
      MobilePhone: MobilePhoneScalar,
      OGRN: OGRNScalar,
      OGRNIP: OGRNIPScalar,
      ORG_INN: OrgINNScalar,
      OTP: OTPScalar,
      PERSONAL_INN: PersonalINNScalar,
      PN: PNScalar,
      Password: PasswordScalar,
      Phone: PhoneScalar,
      RegExp: RegExpScalar,
      Secret: SecretScalar,
      SemanticVersion: SemanticVersionScalar,
      UUID: UUIDScalar,
      ShortString: ShortStringScalar,
      ShortEntityName: ShortEntityNameScalar,
      ChecksumAlgorithm: ChecksumAlgorithmScalar,
      ChecksumValue: ChecksumValueScalar,
      DeliveryChannel: DeliveryChannelScalar,
      Device: DeviceScalar,
      HardwareId: HardwareIdScalar,
      LegalId: LegalIdScalar,
      Path: PathScalar,
      PermissionKey: PermissionKeyScalar,
      SearchString: SearchStringScalar,
      XML: XmlScalar,
      PostalCode: PostalCodeScalar,
    }),
  },
};

/**
 * Список уникальных импортов для скаляров
 */
export const scalarImports = () => {
  const globalImports = <string[]>[...scalarConfig.globalImports];

  const itemsImports = Object.values(scalarConfig.items).reduce((agg, item) => {
    if (item.imports) agg.push(...item.imports);
    return agg;
  }, [] as string[]);

  return [...new Set([...globalImports, ...itemsImports])];
};
