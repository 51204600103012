/**
 * Функция рекурсивной проверки, что в объекте есть поле с ненулевым массивом
 */
export const hasNonEmptyArray = (obj: unknown): boolean => {
  if (obj === undefined || obj === null) {
    return false;
  }

  return Object.values(obj).some((value) => {
    if (value instanceof Date) {
      return true;
    }

    if (Array.isArray(value)) {
      return value.length > 0;
    }

    if (typeof value === 'object' && value !== null) {
      return hasNonEmptyArray(value);
    }
    if (typeof value === 'string' || typeof value === 'boolean') {
      return true;
    }
    return false;
  });
};
