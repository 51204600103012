import { VerificationStatus } from 'shared/api/__generated__';
import { HostGetFilterOptionsQuery } from 'shared/api/__generated__/graphql';

import { HostSelectItem } from '../types';

export const mapHostGetFilterOptionsToSelectItems = (
  hosts: HostGetFilterOptionsQuery['hosts'],
): HostSelectItem[] =>
  hosts.getFilteredList.items.map((item) => {
    return {
      id: item.id,
      name: item.configuration?.hardwareId || item.name,
      legalId: item.configuration?.legalId,
      owner: item.owner.displayShortname,
      isMobile: item.isMobile,
      status: item.status,
      verification: item.currentVerification?.result === VerificationStatus.OK,
    };
  }) || [];
