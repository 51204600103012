import { useLazyQuery } from '@apollo/client';
import { IlUserDeactivated } from '@mc/react-icons/il';
import { AutocompleteRenderOptionState, Stack } from '@mui/material';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getRolePermissions } from 'processes/user';

import { AccountsFilters, BaseStatus } from 'shared/api/__generated__';
import { UserMappedStatus } from 'shared/api/userApi';
import { useErrorHandlerContext } from 'shared/lib/hooks/useErrorHandlerContext';
import { Tag } from 'shared/ui/contents/Tag';
import { Typography } from 'shared/ui/contents/Typography';
import { Checkbox } from 'shared/ui/form/checkboxes/Checkbox';
import {
  SearchSelectServer,
  SearchSelectServerProps,
} from 'shared/ui/form/selects/SearchSelectServer';
import { IconWrapper } from 'shared/ui/helpers/IconWrapper';
import { Tooltip } from 'shared/ui/helpers/Tooltip';

import { USER_GET_FILTER_OPTIONS } from '../api/userGetFilterOptions.gql';
import { mapUserGetFilterOptionsToSelectItems } from '../lib/mapUserGetFilterOptionsToSelectItems';
import { UserSelectItem } from '../types';
import cls from './UserSelect.module.scss';

type ExcludedSearchSelectServerProps =
  | 'getData'
  | 'getDataByValue'
  | 'getOptionLabel'
  | 'getOptionValue'
  | 'loading'
  | 'networkError'
  | 'renderOption';

export type UserSelectProps = (
  | Omit<
      SearchSelectServerProps<UserSelectItem, string, false>,
      ExcludedSearchSelectServerProps
    >
  | Omit<
      SearchSelectServerProps<UserSelectItem, string, true>,
      ExcludedSearchSelectServerProps
    >
) & {
  filters?: AccountsFilters;
};

export function UserSelect(props: UserSelectProps) {
  const {
    placeholder = 'Выберите пользователей',
    label = 'Пользователь',
    menuWidth,
    pageSize = 10,
    filters,
    selectedOptions,
    multiple,
    value,
    onChange,
    ...restProps
  } = props;

  const errorHandlerContext = useErrorHandlerContext();

  const permissions = useSelector(getRolePermissions);
  const canGetTest = permissions.has('subtrees.test');

  const [getUsers, { loading, error: networkError }] = useLazyQuery(
    USER_GET_FILTER_OPTIONS,
    {
      context: errorHandlerContext({
        message: 'Не удалось загрузить список учётных записей',
      }),
    },
  );

  const getData = useCallback(
    async (search: string, offset: number) => {
      try {
        const response = await getUsers({
          variables: {
            filters: {
              search,
              ...(!canGetTest ? { isTest: false } : {}),
              ...filters,
            },
            pagination: { limit: pageSize, offset },
          },
        });

        return {
          options: response.data?.accounts
            ? mapUserGetFilterOptionsToSelectItems(response.data.accounts)
            : [],
          total: response.data?.accounts.getFilteredList?.total || 0,
        };
      } catch (error) {
        console.error(error);

        return {
          options: [],
          total: 0,
        };
      }
    },
    [getUsers, canGetTest, filters, pageSize],
  );

  const getDataByValue = useCallback(
    async (_value?: UserSelectItem['id'][]) => {
      try {
        const response = await getUsers({
          variables: {
            filters: {
              id: { in: _value },
              ...filters,
            },
            pagination: { limit: pageSize, offset: 0 },
          },
        });

        return {
          options: response.data?.accounts
            ? mapUserGetFilterOptionsToSelectItems(response.data?.accounts)
            : [],
        };
      } catch (error) {
        console.error(error);

        return { options: [] };
      }
    },
    [getUsers, filters, pageSize],
  );

  const getOptionValue = useCallback(({ id }: UserSelectItem) => id, []);
  const getOptionLabel = useCallback(
    (option: UserSelectItem) => option.name || '',
    [],
  );
  const getChipLabel = useCallback(
    (option: UserSelectItem) => option.shortName || '',
    [],
  );
  const getAvatar = useCallback(
    (option: UserSelectItem) => option.currentPhotoUrl,
    [],
  );

  const renderOption = useCallback(
    (
      optionProps: React.HTMLAttributes<HTMLLIElement>,
      option: UserSelectItem,
      state: AutocompleteRenderOptionState,
    ) => {
      const { selected, index } = state;
      const {
        id,
        accountType: type,
        status,
        age,
        dob,
        ownerName,
        role,
      } = option;

      const key = `listItem-${index}-${id}`;

      const isDisabled =
        status === BaseStatus.DISABLED_DIRECTLY ||
        status === BaseStatus.DISABLED_INDIRECTLY;

      return (
        <li {...optionProps} key={key}>
          {multiple && (
            <Checkbox
              className={cls.Checkbox}
              size='medium'
              checked={selected}
              id={key}
            />
          )}

          <Stack gap='4px'>
            <Stack
              gap='8px'
              flexDirection='row'
              alignItems='center'
              flexWrap='wrap'
            >
              <Typography variant='t1'>{getOptionLabel(option)}</Typography>

              {isDisabled && (
                <Tooltip title={`${UserMappedStatus[status]}`}>
                  <Tag
                    color='lightpink'
                    icon
                    label={<IconWrapper Svg={IlUserDeactivated} />}
                  />
                </Tooltip>
              )}
            </Stack>

            <Stack
              rowGap='2px'
              columnGap='16px'
              flexDirection='row'
              color='var(--color-monochrome-grey-400)'
              flexWrap='wrap'
            >
              <Typography variant='t3'>{role.name}</Typography>

              <Typography variant='t3'>{`${dob} (${age})`}</Typography>

              <Typography variant='t3'>{ownerName}</Typography>
            </Stack>
          </Stack>
        </li>
      );
    },
    [getOptionLabel, multiple],
  );

  const searchSelectServerProps = {
    ...restProps,
    label,
    menuWidth,
    pageSize,
    placeholder,
    isLoading: loading,
    renderOption,
    getData,
    getDataByValue: selectedOptions ? undefined : getDataByValue,
    selectedOptions,
    networkError,
    getOptionLabel,
    getOptionValue,
    getChipLabel,
    getAvatar,
  };

  return multiple ? (
    <SearchSelectServer
      {...searchSelectServerProps}
      multiple
      onChange={onChange}
      value={value}
    />
  ) : (
    <SearchSelectServer
      {...searchSelectServerProps}
      onChange={onChange}
      value={value}
    />
  );
}
