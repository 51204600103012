import { gql } from 'shared/api/__generated__';

export const USER_GET_FILTER_OPTIONS = gql(/* GraphQL */ `
  query USER_GET_FILTER_OPTIONS(
    $filters: AccountsFilters!
    $pagination: Pagination!
  ) {
    accounts {
      getFilteredList(filters: $filters, pagination: $pagination) {
        ...FragmentUserFilterOptions
      }
    }
  }
`);
