import { gql } from 'shared/api/__generated__';

export const documentGetFilterOptions = gql(/* GraphQL */ `
  query documentGetFilterOptions(
    $filters: IndocsFilter!
    $pagination: Pagination!
  ) {
    indocs {
      getFilteredList(filters: $filters, pagination: $pagination) {
        items {
          id
          type
          status
          name
          issuedAt
          addedAt
          expiresAt
          owner {
            personalData {
              name
              surname
              patronymic
            }
          }
        }
        total
      }
    }
  }
`);
