import { IlUser3 as UsersIcon } from '@mc/react-icons/il';
import { Stack } from '@mui/system';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { getCanMakeActionRule } from 'processes/user';

import { EmployeeImportRoutePaths, UsersRoutePaths } from 'shared/const/router';
import { classNames } from 'shared/lib/utils/classNames';
import { Dropdown, DropdownItem } from 'shared/ui/buttons/Dropdown';
import { Typography } from 'shared/ui/contents/Typography';
import { IconWrapper } from 'shared/ui/helpers/IconWrapper';

import clsContainer from '../NavbarMenuItemContainer/NavbarMenuItemContainer.module.scss';
import cls from './NavbarAccountsMenu.module.scss';

export const NavbarAccountsMenu = () => {
  const hasAccessToAccounts = useSelector(
    getCanMakeActionRule('account.method.get_filtered_list'),
  );
  const hasAccessToImports = useSelector(
    getCanMakeActionRule('imports.method.get_filtered_list'),
  );

  const navigate = useNavigate();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  const isActive = /users|employee-import/gi.test(location.pathname);

  const getContainerClass = () => {
    if (isActive) return cls.AccountsAnchorActive;
    if (isOpen) return cls.AccountsAnchorOpen;
    return cls.AccountsAnchor;
  };

  /**
   * Список элементов для Dropdown.
   * Высчитывается на основе прав доступа.
   */
  const dropdownItems = useMemo(() => {
    const arr: DropdownItem[] = [];

    if (hasAccessToAccounts) {
      arr.push({
        id: 'users',
        dataTestId: 'navbarUsersSubMenuItem',
        content: <Typography variant='t2'>Пользователи</Typography>,
        onClick: () => navigate(UsersRoutePaths.getRouteUsers()),
      });
    }

    if (hasAccessToImports) {
      arr.push({
        id: 'users-import',
        dataTestId: 'navbarImportSubMenuItem',
        content: <Typography variant='t2'>Импорт</Typography>,
        onClick: () => navigate(EmployeeImportRoutePaths.getEmployeeImport()),
      });
    }

    return arr;
  }, [hasAccessToAccounts, hasAccessToImports, navigate]);

  return (
    <Dropdown
      items={dropdownItems}
      trigger={
        <Stack
          data-testid='navbarUsersMenuItem'
          className={classNames(clsContainer.Item, {}, [getContainerClass()])}
          onClick={() => {
            setIsOpen(true);
            navigate(UsersRoutePaths.getRouteUsers());
          }}
        >
          <Stack className={cls.AccountsContainer}>
            <IconWrapper Svg={UsersIcon} />
            <Typography variant='h4' className={cls.Title}>
              Пользователи
            </Typography>
          </Stack>
        </Stack>
      }
      onClose={() => setIsOpen(false)}
      onTrigger='hover'
    />
  );
};
