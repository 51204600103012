import { DocumentGetFilterOptionsQuery } from 'shared/api/__generated__/graphql';
import { formatDate } from 'shared/lib/utils/dates';
import { euDateFormat } from 'shared/lib/utils/dates/formatDate/formatDate';

import { DocumentSelectItem } from '../types';

export const mapDocumentGetFilterOptionsToSelectItems = (
  docs: DocumentGetFilterOptionsQuery['indocs'],
): DocumentSelectItem[] =>
  docs.getFilteredList?.items.map((item) => {
    return {
      id: item.id,
      name: item.name,
      type: item.type,
      status: item.status,
      issuedAt: formatDate(item?.issuedAt, euDateFormat),
      expiresAt: item?.expiresAt
        ? formatDate(item?.expiresAt, euDateFormat)
        : 'бессрочно',
    };
  }) || [];
