import { configureStore } from '@reduxjs/toolkit';
/*
 * Импорт исправляет ошибку в redux-devtools:
 * "Symbol.observable as defined by Redux and Redux DevTools do not match.
 * This could cause your app to behave differently if the DevTools are not loaded.
 * Consider polyfilling Symbol.observable before Redux is imported or avoid
 * polyfilling Symbol.observable altogether.".
 *
 * https://github.com/reduxjs/redux-devtools/issues/1002#issuecomment-1010297615
 */
import 'symbol-observable';

import { authSliceReducer } from 'processes/auth';
import { signingReducer } from 'processes/documentSigning';
import { filtersSliceReducer } from 'processes/filters/model/slice/filtersSlice';
import { lineSliceReducer } from 'processes/line';
import { medCabinetReducer } from 'processes/medcabinet';
import { userReducer } from 'processes/user';

import { orgUnitDetailsReducer } from 'entities/OrgUnit';

export const store = configureStore({
  devTools: import.meta.env.__DEV__,
  reducer: {
    user: userReducer,
    auth: authSliceReducer,
    orgUnitDetails: orgUnitDetailsReducer,
    medCabinet: medCabinetReducer,
    signingDocuments: signingReducer,
    filters: filtersSliceReducer,
    line: lineSliceReducer,
  },
});
