import { useLazyQuery } from '@apollo/client';
import { IlHome2, IlHomeError, IlPay } from '@mc/react-icons/il';
import { AutocompleteRenderOptionState, Stack } from '@mui/material';
import { unreachable } from '@rsdk/common';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getRolePermissions } from 'processes/user';

import { BaseStatus, BillingProfileOrigin } from 'shared/api/__generated__';
import { useErrorHandlerContext } from 'shared/lib/hooks/useErrorHandlerContext';
import { Tag } from 'shared/ui/contents/Tag';
import { Typography } from 'shared/ui/contents/Typography';
import { Checkbox } from 'shared/ui/form/checkboxes/Checkbox';
import {
  SearchSelectServer,
  SearchSelectServerProps,
} from 'shared/ui/form/selects/SearchSelectServer';
import { IconWrapper } from 'shared/ui/helpers/IconWrapper';
import { Tooltip } from 'shared/ui/helpers/Tooltip';

import { TOrgUnitsFilters } from '../../../types';
import { OrgUnitMappedStatus } from '../../../utils';
import { OrgUnitFilterOptionsCommonInfo } from '../api/orgUnitFilterOptions.types';
import { ORG_UNIT_GET_FILTER_OPTIONS } from '../api/orgUnitGetFilterOptions.gql';
import { mapOrgUnitGetFilterOptionsToSelectItems } from '../lib/mapOrgUnitGetFilterOptionsToSelectItems';
import { OrgUnitSelectItem } from '../types';
import { OrgUnitInfoContent } from './OrgUnitInfoContent';
import cls from './OrgUnitSelect.module.scss';

type ExcludedSearchSelectServerProps =
  | 'getData'
  | 'getDataByValue'
  | 'getOptionLabel'
  | 'getOptionValue'
  | 'loading'
  | 'networkError'
  | 'renderOption';

export type OrgUnitSelectProps = (
  | Omit<
      SearchSelectServerProps<OrgUnitSelectItem, string, false>,
      ExcludedSearchSelectServerProps
    >
  | Omit<
      SearchSelectServerProps<OrgUnitSelectItem, string, true>,
      ExcludedSearchSelectServerProps
    >
) & {
  filters?: TOrgUnitsFilters;
  isNotAccessOrganization?: boolean;
};

export function OrgUnitSelect(props: OrgUnitSelectProps) {
  const {
    placeholder: _placeholder,
    label = 'Организация',
    selectedOptions,
    filters,
    isNotAccessOrganization,
    multiple,
    value,
    onChange,
    ...restProps
  } = props;

  const errorHandlerContext = useErrorHandlerContext();

  const permissions = useSelector(getRolePermissions);
  const canGetTest = permissions.has('subtrees.test');

  const placeholder = useMemo(() => {
    if (_placeholder !== undefined) return _placeholder;
    return multiple ? 'Выберите организации' : 'Выберите организацию';
  }, [_placeholder, multiple]);

  const [getOrgUnits, { loading, error: networkError }] = useLazyQuery(
    ORG_UNIT_GET_FILTER_OPTIONS,
    {
      context: errorHandlerContext({
        message: 'Не удалось загрузить организации',
      }),
    },
  );

  const getData = useCallback(
    async (search: string, offset: number) => {
      try {
        const response = await getOrgUnits({
          variables: {
            filters: {
              ...(filters || {}),
              search,
              ...(!canGetTest ? { isTest: false } : {}),
            },
            pagination: { limit: 10, offset },
          },
        });

        return isNotAccessOrganization
          ? {
              options: [],
              total: 0,
            }
          : {
              options: response.data?.orgUnits
                ? mapOrgUnitGetFilterOptionsToSelectItems(
                    response.data.orgUnits,
                  )
                : [],
              total: response.data?.orgUnits.getFilteredList?.total || 0,
            };
      } catch (error) {
        console.error(error);

        return {
          options: [],
          total: 0,
        };
      }
    },
    [getOrgUnits, filters, canGetTest],
  );

  const getDataByValue = useCallback(
    async (_value?: OrgUnitSelectItem['id'][]) => {
      if (!_value?.length) {
        return {
          options: [],
        };
      }
      try {
        const response = await getOrgUnits({
          variables: {
            filters: {
              ids: { in: _value },
              ...(!canGetTest ? { isTest: false } : {}),
            },
            pagination: { limit: _value?.length, offset: 0 },
          },
        });

        return {
          options: response.data?.orgUnits
            ? mapOrgUnitGetFilterOptionsToSelectItems(response.data.orgUnits)
            : [],
        };
      } catch (error) {
        console.error(error);

        return {
          options: [],
        };
      }
    },
    [getOrgUnits, canGetTest],
  );

  const getOptionLabel = useCallback(
    (option: OrgUnitSelectItem) => option.shortname || '',
    [],
  );

  const renderOption = useCallback(
    (
      optionProps: React.HTMLAttributes<HTMLLIElement>,
      option: OrgUnitSelectItem,
      state: AutocompleteRenderOptionState,
    ) => {
      const { selected, index } = state;
      const { id, isMedOperator, type, status, billingProfile, commonInfo } =
        option;

      const key = `listItem-${index}-${id}`;

      const isDisabled =
        status === BaseStatus.DISABLED_DIRECTLY ||
        status === BaseStatus.DISABLED_INDIRECTLY;

      const isSelfBilling = billingProfile === BillingProfileOrigin.SELF;

      const commonInfoContent = (
        commonInfoValue: OrgUnitFilterOptionsCommonInfo,
      ) => {
        switch (commonInfoValue?.__typename) {
          case 'CompanyInfo':
            return (
              <OrgUnitInfoContent
                type={type}
                inn={commonInfoValue.inn}
                kpp={commonInfoValue.kpp}
              />
            );
          case 'IndividualEntrepreneurInfo':
            return (
              <OrgUnitInfoContent
                type={type}
                inn={commonInfoValue.innIp}
                ogrnip={commonInfoValue.ogrnip}
              />
            );
          case 'DepartmentInfo':
          case 'GroupInfo':
            return <OrgUnitInfoContent type={type} />;
          default:
            return unreachable(commonInfoValue);
        }
      };

      return (
        <li {...optionProps} key={key}>
          {multiple && (
            <Checkbox
              className={cls.Checkbox}
              size='medium'
              checked={selected}
              id={key}
            />
          )}
          <Stack gap='4px'>
            <Stack flexDirection='row' gap='8px' alignItems='center'>
              <Typography variant='t1'>{getOptionLabel(option)}</Typography>

              {isSelfBilling && (
                <Tooltip title='Контрагент'>
                  <Tag color='blue' icon label={<IconWrapper Svg={IlPay} />} />
                </Tooltip>
              )}

              {isMedOperator && (
                <Tooltip title='Мед. оператор'>
                  <Tag
                    color='blue'
                    icon
                    label={<IconWrapper Svg={IlHome2} />}
                  />
                </Tooltip>
              )}

              {isDisabled && (
                <Tooltip title={OrgUnitMappedStatus[status]}>
                  <Tag
                    color='lightpink'
                    icon
                    label={<IconWrapper Svg={IlHomeError} />}
                  />
                </Tooltip>
              )}
            </Stack>

            {commonInfo && commonInfoContent(commonInfo)}
          </Stack>
        </li>
      );
    },
    [getOptionLabel, multiple],
  );

  const searchSelectServerProps = {
    ...restProps,

    renderOption,
    label,
    placeholder,
    isLoading: loading,
    getData,
    getDataByValue: selectedOptions ? undefined : getDataByValue,
    selectedOptions,
    networkError,
    getOptionLabel,
  };

  return multiple ? (
    <SearchSelectServer
      {...searchSelectServerProps}
      multiple
      value={value}
      onChange={onChange}
    />
  ) : (
    <SearchSelectServer
      {...searchSelectServerProps}
      value={value}
      onChange={onChange}
    />
  );
}
