import { gql } from 'shared/api/__generated__';

export const UserGetIndocForUnban = gql(/* GraphQL */ `
  query UserGetIndocForUnban($id: UUID!) {
    indocs {
      getOne(id: $id) {
        type
      }
    }
  }
`);
