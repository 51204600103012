import { useCallback, useMemo } from 'react';

import { AlertTypes } from 'shared/ui/helpers/Alert';

import { toast } from './NotificationsContainer';

export interface NotificationState {
  type: AlertTypes;
  title: string;
  message: string;
  xRequestId?: string;
  button?: {
    text: string;
  } & (
    | {
        /** Путь, который используется в `useNavigate()` */
        navigatePath: string;
      }
    | {
        /** Функция перехода по кнопке  */
        onClick: () => void;
      }
  );
}

export type ShowTypeNotificationOptions = {
  title?: NotificationState['title'];
  message: NotificationState['message'];
  xRequestId?: NotificationState['xRequestId'];
  button?: NotificationState['button'];
};

export type ShowNotificationOptions = ShowTypeNotificationOptions & {
  type: NotificationState['type'];
};

export type ShowNotification = (options: ShowNotificationOptions) => void;
export type ShowTypeNotification = (
  options: ShowTypeNotificationOptions,
) => void;

export type NotificationsActions = {
  showNotification: ShowNotification;
  showErrorNotification: ShowTypeNotification;
  showInfoNotification: ShowTypeNotification;
  showSuccessNotification: ShowTypeNotification;
};

export const useNotifications = (): NotificationsActions => {
  const showNotification = useCallback((options: ShowNotificationOptions) => {
    toast(options);
  }, []);

  const showErrorNotification = useCallback(
    (options: ShowTypeNotificationOptions) => {
      showNotification({ type: AlertTypes.ERROR, ...options });
    },
    [showNotification],
  );
  const showInfoNotification = useCallback(
    (options: ShowTypeNotificationOptions) =>
      showNotification({ type: AlertTypes.INFO, ...options }),
    [showNotification],
  );
  const showSuccessNotification = useCallback(
    (options: ShowTypeNotificationOptions) =>
      showNotification({ type: AlertTypes.SUCCESS, ...options }),
    [showNotification],
  );

  return useMemo(
    () => ({
      showErrorNotification,
      showInfoNotification,
      showNotification,
      showSuccessNotification,
    }),
    [
      showErrorNotification,
      showInfoNotification,
      showNotification,
      showSuccessNotification,
    ],
  );
};
