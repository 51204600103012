import { OrgUnitDetailsPage, OrgUnitsPage } from 'pages/OrgUnit';

import { OrgUnitsRouteNames, OrgUnitsRoutePaths } from 'shared/const/router';

import { TRouteProps } from './types';

export const OrgUnitsRoutes: Record<OrgUnitsRouteNames, TRouteProps> = {
  [OrgUnitsRouteNames.ORG_UNITS]: {
    path: OrgUnitsRoutePaths.getRouteOrgUnits(),
    element: <OrgUnitsPage />,
    isAuth: true,
    needRules: ['org_unit.method.get_filtered_list'],
  },
  [OrgUnitsRouteNames.ORG_UNIT_DETAILS_INFO]: {
    path: OrgUnitsRoutePaths.getRouteOrgUnitDetailsInfo(':id'),
    element: <OrgUnitDetailsPage />,
    isAuth: true,
    needRules: ['org_unit.method.get_one'],
  },
  [OrgUnitsRouteNames.ORG_UNIT_DETAILS_ORGS]: {
    path: OrgUnitsRoutePaths.getRouteOrgUnitDetailsOrgs(':id'),
    element: <OrgUnitDetailsPage />,
    isAuth: true,
    needRules: ['org_unit.method.get_one'],
  },
  [OrgUnitsRouteNames.ORG_UNIT_DETAILS_EMPLOYEES]: {
    path: OrgUnitsRoutePaths.getRouteOrgUnitDetailsEmployees(':id'),
    element: <OrgUnitDetailsPage />,
    isAuth: true,
    needRules: ['org_unit.method.get_one'],
  },
  [OrgUnitsRouteNames.ORG_UNIT_DETAILS_HOSTS]: {
    path: OrgUnitsRoutePaths.getRouteOrgUnitDetailsHosts(':id'),
    element: <OrgUnitDetailsPage />,
    isAuth: true,
    needRules: ['org_unit.method.get_one'],
  },
  [OrgUnitsRouteNames.ORG_UNIT_DETAILS_AUDIT_HISTORY]: {
    path: OrgUnitsRoutePaths.getRouteOrgUnitDetailsAuditHistory(':id'),
    element: <OrgUnitDetailsPage />,
    isAuth: true,
    needRules: ['audit.org_unit_history'],
  },
  [OrgUnitsRouteNames.ORG_UNIT_DETAILS_HISTORY]: {
    path: OrgUnitsRoutePaths.getRouteOrgUnitDetailsHistory(':id'),
    element: <OrgUnitDetailsPage />,
    isAuth: true,
    needRules: ['org_unit.method.get_one'],
  },
  [OrgUnitsRouteNames.ORG_UNIT_DETAILS_EXAMS]: {
    path: OrgUnitsRoutePaths.getRouteOrgUnitDetailsExams(':id'),
    element: <OrgUnitDetailsPage />,
    isAuth: true,
    needRules: ['org_unit.method.get_one'],
  },
  [OrgUnitsRouteNames.ORG_UNIT_DETAILS_SETTINGS]: {
    path: OrgUnitsRoutePaths.getRouteOrgUnitDetailsSettings(':id'),
    element: <OrgUnitDetailsPage />,
    isAuth: true,
    needRules: ['org_unit.method.get_one'],
  },
};
