import translations from '@mc/translation/dist/translations/ru.d';
import { GraphQLFormattedError } from 'graphql';

import { i18n } from '../../external/i18n';

const { t } = i18n;

/**
 * Формат ошибок, в котором вместо текста приходит код ошибки.
 */
export type GraphQLFormattedErrorWithKey = Omit<
  GraphQLFormattedError,
  'message'
> & {
  message: { errorKey: keyof (typeof translations)['errors'] };
};

/**
 * TSGuard - проверяет, что ошибка имеет текстовый формат.
 */
export const isGraphQLFormattedError = (
  error: GraphQLFormattedError | GraphQLFormattedErrorWithKey,
): error is GraphQLFormattedError => typeof error.message === 'string';

/**
 * TSGuard - проверяет, что ошибка имеет код для дальнейшей расшифровки.
 */
export const isGraphQLFormattedErrorWithKey = (
  error: GraphQLFormattedError | GraphQLFormattedErrorWithKey,
): error is GraphQLFormattedErrorWithKey =>
  typeof error.message === 'object' && 'errorKey' in error.message;

/**
 * Обрезает ошибку, с нечитаемым текстом после '>' или расшифровывает код ошибки
 */
export const tError = (
  error: GraphQLFormattedError | GraphQLFormattedErrorWithKey,
): string => {
  if (isGraphQLFormattedError(error)) {
    return error.message?.split('>')[0] || '';
  }

  if (isGraphQLFormattedErrorWithKey(error)) {
    if (t) {
      return t(`errors.${error.message.errorKey}.text`);
    }
    return error.message.errorKey;
  }

  return '';
};

/**
 * Обрезает ошибку, с нечитаемым текстом после '>' или расшифровывает код ошибки
 */
export const tErrors = (
  errors: readonly (GraphQLFormattedError | GraphQLFormattedErrorWithKey)[],
) => {
  return errors.map(tError).join('; ');
};
