import { ApolloProvider } from '@apollo/client';
import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { NotificationsContainer } from 'processes/notifications';

import { apolloClientMain } from 'shared/api/_instances';
import { initSentry } from 'shared/lib/external/sentry';
import { logger } from 'shared/lib/utils/logger';

import './config/i18n';
import { ErrorBoundary } from './providers/error';
import { AppRouter } from './providers/router';
import { StoreProvider } from './providers/StoreProvider';
import { ThemeProvider } from './providers/ThemeProvider';
import './styles/index.scss';

const appLogger = logger('APP');
appLogger(`envs`, {
  DEV: import.meta.env.__DEV__,
  VERSION: import.meta.env.__VERSION__,
  STAGE_NAME: import.meta.env.__STAGE_NAME__,
  API_GQLGW_URL: import.meta.env.__API_GQLGW_URL__,
  API_GQLGW_WS_URL: import.meta.env.__API_GQLGW_WS_URL__,
  API_REST_URL: import.meta.env.__API_REST_URL__,
  TEST_PANEL_API_URL: import.meta.env.__TEST_PANEL_API_URL__,
  API_SIGNALING: import.meta.env.__API_SIGNALING__,
});

initSentry();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <StoreProvider>
          <ApolloProvider client={apolloClientMain}>
            <ThemeProvider>
              <ErrorBoundary>
                <div className='content-page'>
                  <AppRouter />
                </div>
                <NotificationsContainer />
              </ErrorBoundary>
            </ThemeProvider>
          </ApolloProvider>
        </StoreProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>,
);
