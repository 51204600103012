import { IlClose } from '@mc/react-icons/il';
import { Stack } from '@mui/system';
import { toast as _toast, ToastContainer } from 'react-toastify';
import { CloseButtonProps } from 'react-toastify/dist/components';
import 'react-toastify/dist/ReactToastify.css';

import { AlertTypes } from 'shared/ui/helpers/Alert';
import { getButtonData } from 'shared/ui/helpers/Alert/lib/helpers';
import { AlertWithRef as Alert } from 'shared/ui/helpers/Alert/ui/Alert';
import { IconWrapper } from 'shared/ui/helpers/IconWrapper';
import { Portal } from 'shared/ui/helpers/Portal';

import cls from './NotificationsContainer.module.scss';

interface ToastTemplateProps {
  type: AlertTypes;
  title?: string;
  message: string;
  xRequestId?: string;
  button?: {
    text: string;
  } & (
    | {
        navigatePath: string;
      }
    | {
        onClick: () => void;
      }
  );
}

/** Кнопка закрытия тоста */
const CloseButton = ({ closeToast }: CloseButtonProps) => (
  <Stack className={cls.CloseButton} onClick={closeToast}>
    <IconWrapper Svg={IlClose} />
  </Stack>
);

/** Шаблон тоста */
export const ToastTemplate = (props: ToastTemplateProps) => {
  const { type, title, message, button, xRequestId } = props;

  const correctButtonInfo = getButtonData({
    type,
    xRequestId,
    button,
  });

  const _title =
    title ||
    {
      [AlertTypes.SUCCESS]: 'Успешно',
      [AlertTypes.ERROR]: 'Ошибка',
      [AlertTypes.INFO]: 'Информация',
    }[type];

  return (
    <Alert
      type={type}
      title={_title}
      message={message}
      buttonInfo={correctButtonInfo}
    />
  );
};

type ToastParams = ToastTemplateProps;

/** Функция для вывода тостов */
export const toast = (params: ToastParams) => {
  _toast(<ToastTemplate {...params} />, {
    type: params.type,
    icon: false,
    pauseOnFocusLoss: false,
    /** Предотвращает дубликаты */
    toastId: `${params.type}-${params.title}-${params.message}`,
  });
};

/** Контейнер для вывода уведомлений */
export const NotificationsContainer = () => {
  return Portal({
    children: (
      <ToastContainer
        className={cls.Toastify}
        autoClose={7000}
        draggable
        draggablePercent={60}
        toastClassName={cls.Toast}
        progressClassName={cls.Progress}
        closeButton={CloseButton}
      />
    ),
    container: document.body,
  });
};
