import { NotificationsActions } from 'processes/notifications/ui/Notifications.context';

import { FileMeta } from './downloadFileByLink.types';

/**
 * Извлечение названия файла из заголовка s3
 * NOTE: при запросе с localhost доступ к заголовку Content-Disposition будет ограничен
 */
const getFilenameFromResponse = (response: Response) => {
  try {
    if (response.ok) {
      const contentDispositionHeader = response.headers.get(
        'Content-Disposition',
      );

      if (contentDispositionHeader) {
        const [, filename] =
          contentDispositionHeader.match(/filename=([^;]+)/) || [];

        if (filename) {
          return decodeURIComponent(filename);
        }
      }
    }
  } catch (err) {
    console.error('Не удалось получить название файла из заголовков', err);
  }

  return null;
};

/**
 * Формирование названия файла на основе мета-данных
 */
const createFilenameFromMeta = (meta: FileMeta) => {
  const { name, ext } = meta;
  const extension = ext ? `.${ext}` : '';

  return `${name}${extension}` || null;
};

/**
 * Извлечение названия файла из url
 */
const createFilenameFromUrl = (fileLink: string | URL) => {
  try {
    const urlObj = new URL(fileLink);
    return urlObj.pathname.split('/').pop() ?? '';
  } catch (err) {
    console.error('Не удалось получить название файла из url', err);
  }

  return null;
};

/**
 * Скачивание файла по ссылке
 */
export const downloadFileByLink = async (
  /** Ссылка на файл */
  fileLink: string | URL,
  /** Мета-данные файла */
  meta?: FileMeta,
  /** Обработчик уведомлений ошибок */
  notificationsActions?: NotificationsActions,
  /** Токен авторизации (нужен не во всех запросах) */
  token?: Nullable<string>,
) => {
  try {
    const headers = new Headers();

    if (token) {
      headers.append('Authorization', `Bearer ${token}`);
    }

    const response = await fetch(fileLink, {
      method: 'GET',
      headers,
    });

    if (!response.ok) {
      throw new Error(`${response.status} ${response.statusText}`);
    }

    const blob = await response.blob();

    const downloadLink = document.createElement('a');

    downloadLink.download =
      // пытаемся получить имя файла из заголовка ...
      getFilenameFromResponse(response) ||
      // ... или из мета-данных
      (meta && createFilenameFromMeta(meta)) ||
      // ... пытаемся извлечь из url
      createFilenameFromUrl(fileLink) ||
      // ... и если уж ничего не удалось, то указываем:
      'untitled_file';

    downloadLink.href = URL.createObjectURL(blob);

    downloadLink.click();
  } catch (err) {
    console.error('Не удалось скачать файл', err);
    notificationsActions?.showErrorNotification({
      message: 'Не удалось скачать файл',
    });
  }
};

/**
 * Скачивает файл если он приходит в формате Base64
 */
export const downloadFileByBase64 = (data: {
  filename: string;
  mimetype: string;
  file: string;
}) => {
  const { file, filename, mimetype } = data;

  try {
    const byteString = atob(file);
    const byteArray = Uint8Array.from(byteString, (char) => char.charCodeAt(0));

    const blob = new Blob([byteArray], { type: mimetype });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};
