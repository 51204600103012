import { GraphQLError, GraphQLFormattedError } from 'graphql';
import { useSelector } from 'react-redux';

import { useNotifications } from 'processes/notifications/ui/Notifications.context';
import { getCanMakeActionRule } from 'processes/user';

import { tErrors } from '../../utils/getErrorMessage';

export type ErrorHandlerContextProps = {
  message?: string;
  title?: string;
};

/**
 * Отображает ошибку при выполнении запроса
 * и даёт ссылку на логи в kibana
 */
export const useErrorHandlerContext = () => {
  const notificationsActions = useNotifications();

  const canOpenKibana = useSelector(getCanMakeActionRule('kibana.logs'));

  const errorHandlerContext = ({
    message: defaultMesage = '', // Дефолтное сообщение об ошибке
    title,
  }: ErrorHandlerContextProps) => {
    return {
      errorHandler: ({
        requestId,
        errors,
      }: {
        requestId?: string;
        errors: (Error | GraphQLError | GraphQLFormattedError)[];
      }) => {
        notificationsActions.showErrorNotification({
          title,
          message: errors?.length
            ? tErrors(errors) || defaultMesage
            : defaultMesage,
          xRequestId: canOpenKibana ? requestId : undefined,
        });
      },
    };
  };

  return errorHandlerContext;
};
