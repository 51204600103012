import { OrgUnit } from 'shared/api/__generated__';
import { OrgUnitGetFilterOptionsQuery } from 'shared/api/__generated__/graphql';

import { OrgUnitSelectItem } from '../types';

export const mapOrgUnitGetFilterOptionsToSelectItems = (
  orgUnits: OrgUnitGetFilterOptionsQuery['orgUnits'],
): OrgUnitSelectItem[] =>
  orgUnits.getFilteredList.items.map((item) => {
    return {
      id: item.id,
      name: item.displayShortname,
      shortname: item.displayShortname,
      type: item.type,
      pathToRoot: item.pathToRoot
        .map((pathItem) => pathItem.displayShortname)
        .reverse(),
      rootAccountsCount: item.subtreeCounts.rootAccounts,
      isTest: item.isTest,
      status: item.status,
      commonInfo: item.commonInfo,
      isMedOperator: item.isMedOperator,
      billingProfile: item.billingProfile.origin,
      medOperatorProfile: item.medOperatorProfile,
    };
  }) || [];

export const mapOrgUnitsToSelectItems = (
  orgUnits?:
    | ({
        id: OrgUnit['id'];
        displayShortname: OrgUnit['displayShortname'];
        type?: OrgUnit['type'];
        pathToRoot?: OrgUnit['pathToRoot'];
        subtreeCounts?: OrgUnit['subtreeCounts'];
      } | null)[]
    | null,
): OrgUnitSelectItem[] =>
  orgUnits
    ?.filter((item): item is OrgUnit => item !== null)
    .map((item) => {
      return {
        id: item.id,
        name: item.displayShortname,
        shortname: item.displayShortname,
        type: item.type,
        pathToRoot: item.pathToRoot
          ?.map((pathItem) => pathItem.displayName)
          ?.reverse(),
        rootAccountsCount: item.subtreeCounts?.rootAccounts,
        isTest: item.isTest,
      };
    }) || [];
