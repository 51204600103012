import { useCallback, useEffect, useState } from 'react';

import { useDebounce } from './useDebounce';

const regexDebounceMinimumLength = /^.{1,2}$/;

type UseDebouncedSearch = {
  /** Значение из фильтра variables */
  variableSearch?: string | null;

  /** Callback изменения значения в фильтре variables */
  changeVariables: (value: string) => void;

  /** Нужно ли обрезать пробелы при поиске */
  shouldTrim?: boolean;
};

/**
 * Обрабатывает строку поиска с задержкой.
 * Он позволяет обновлять переменные поиска только после того, как пользователь завершил ввод,
 * и проверяет строку на наличие ошибок (например, минимальная длина).
 *
 * @example
 * const { search, setSearch, isSearchFieldError } = useDebouncedSearch({
 *  variableSearch: initialSearch,
 *   changeVariables: (val) => {
 *     setInitialSearch(val);
 *   },
 * });
 */
export const useDebouncedSearch = ({
  variableSearch,
  changeVariables,
  shouldTrim = true,
}: UseDebouncedSearch) => {
  const [search, _setSearch] = useState<string>(variableSearch || '');
  const [isSearchFieldError, setIsSearchFieldError] = useState(false);

  /** Значение поиска с задержкой (500мс по умолчанию) */
  const debouncedSearch = useDebounce(search);

  const setSearch = useCallback((value: string) => {
    _setSearch(value);
  }, []);

  useEffect(() => {
    /** Окончательное значение поиска, в зависимости от флага shouldTrim */
    const finalSearch = shouldTrim ? debouncedSearch.trim() : debouncedSearch;

    if (regexDebounceMinimumLength.test(debouncedSearch)) {
      setIsSearchFieldError(true);
    } else if (variableSearch !== finalSearch) {
      // Если значение поиска изменилось, вызывается функция изменения переменной поиска
      changeVariables(finalSearch);
      setIsSearchFieldError(false);
    }
  }, [changeVariables, debouncedSearch, shouldTrim, variableSearch]);

  return {
    search,
    setSearch,
    isSearchFieldError,
  };
};
