import { ApolloError } from '@apollo/client';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useDebouncedSearch } from 'shared/lib/hooks/useDebouncedSearch';

import { AutocompleteValue } from '../../inputs/UniversalAutocomplete';
import {
  SearchSelectBase,
  SearchSelectBaseProps,
  SearchSelectOption,
} from '../SearchSelectBase/SearchSelectBase';

interface SearchSelectServerMultipleProps<TOption, TValue> {
  multiple: true;
  value: AutocompleteValue<TValue, true>;
  /** Обработчик изменения значения */
  onChange: (
    value: AutocompleteValue<TValue, true>,
    options: TOption[],
    selectedOption?: TOption | null,
  ) => void;
}
interface SearchSelectServerSingleProps<TOption, TValue> {
  multiple?: false;
  value: AutocompleteValue<TValue, false>;
  /** Обработчик изменения значения */
  onChange: (
    value: AutocompleteValue<TValue, false>,
    options: TOption[],
    selectedOption?: TOption | null,
  ) => void;
}

export type SearchSelectServerProps<
  TOption,
  TValue,
  Multi extends boolean | undefined,
> = Omit<
  SearchSelectBaseProps<TOption, TValue, Multi>,
  | 'clearSearchOnClose'
  | 'error'
  | 'isFullyLoaded'
  | 'loadMore'
  | 'multiple'
  | 'onChange'
  | 'onChangeSearch'
  | 'onCloseHandler'
  | 'onOpenHandler'
  | 'options'
  | 'search'
  | 'selectedOptions'
  | 'topErrorMessage'
  | 'value'
> & {
  /** Функция получения элементов списка по строке поиска */
  getData: (
    search: string,
    offset: number,
  ) => Promise<{ options: TOption[]; total: number }>;
  /** Размер страницы для подгрузки элементов при скроле */
  pageSize?: number;
  /** Ширина выпадающего списка */
  menuWidth?: number;
  /** Функция получения выбранных элементов списка по значению */
  getDataByValue?: (value?: TValue[]) => Promise<{ options: TOption[] }>;
  /** Выбранные элементы списка. Используется, если getDataByValue === undefined  */
  selectedOptions?: TOption[];
  /** Ошибка загрузки данных */
  networkError?: ApolloError;
} & TernaryUnion<
    Multi,
    SearchSelectServerMultipleProps<TOption, TValue>,
    SearchSelectServerSingleProps<TOption, TValue>
  >;

const getFirstError = (errors: { value: boolean; message: string }[]) => {
  return errors.find((error) => error.value);
};

const defaultGetOptionValue = <TOption, TValue>(option: TOption) =>
  (option as SearchSelectOption).id as TValue;

const defaultGetOptionLabel = <TOption, TValue>(option: TOption) =>
  (option as SearchSelectOption).label || '';

export function SearchSelectServer<TOption, TValue>(
  props: SearchSelectServerProps<TOption, TValue, undefined>,
): JSX.Element {
  const {
    selectedOptions: selectedOptionsExternal,
    getData,
    getDataByValue,
    isLoading: isLoadingExternal,
    pageSize = 10,
    networkError,
    menuWidth,

    hasError,
    helperText,

    multiple,
    onChange,
    value,
    getOptionValue = defaultGetOptionValue,
    getOptionLabel = defaultGetOptionLabel,
    ...restProps
  } = props;

  const isInternalSelectedItems = !!getDataByValue;

  const [isOpen, setIsOpen] = useState(false);
  const setIsOpenTrue = useCallback(() => setIsOpen(true), []);
  const setIsOpenFalse = useCallback(() => setIsOpen(false), []);

  // OPTIONS
  const total = useRef(0);
  const optionsCount = useRef(0);
  const optionsOffset = useRef(0);

  const [optionsServer, setOptionsServer] = useState<TOption[]>([]);

  // FETCH OPTIONS
  const [isLoadingInternal, setIsLoadingInternal] = useState(false);
  const [filterSearch, setFilterSearch] = useState<string>('');
  const { search, setSearch, isSearchFieldError } = useDebouncedSearch({
    variableSearch: filterSearch,
    changeVariables: setFilterSearch,
  });

  const fetchOptions = useCallback(
    async (offset: number) => {
      setIsLoadingInternal(true);

      const data = await getData(filterSearch, offset);

      setOptionsServer((_options) => {
        const res =
          offset === 0 ? data.options : [..._options, ...data.options];
        optionsCount.current = res.length;
        return res;
      });

      total.current = data.total;
      optionsOffset.current = offset;

      setIsLoadingInternal(false);
    },
    [getData, filterSearch],
  );

  useEffect(() => {
    // Первоначальная загрузка данных
    if (isOpen) fetchOptions(0);
  }, [fetchOptions, isOpen]);

  useEffect(() => {
    if (networkError) setOptionsServer([]);
  }, [networkError]);

  const loadMore = useCallback(async () => {
    if (total.current > optionsCount.current) {
      await fetchOptions(optionsOffset.current + pageSize);
    }
  }, [fetchOptions, pageSize]);

  // SELECTED OPTIONS
  const [selectedOptionsInternal, setSelectedOptionsInternal] = useState<
    TOption[]
  >([]);

  const selectedOptions = useMemo(
    () =>
      (isInternalSelectedItems
        ? selectedOptionsInternal
        : selectedOptionsExternal) || [],
    [isInternalSelectedItems, selectedOptionsInternal, selectedOptionsExternal],
  );

  // FETCH SELECTED OPTIONS
  useEffect(() => {
    if (!isInternalSelectedItems) return;

    let valueArray: TValue[];
    if (Array.isArray(value)) {
      valueArray = value;
    } else {
      valueArray = value ? [value] : [];
    }

    async function getSelectedOptions() {
      if (valueArray.length) {
        const data = await getDataByValue?.(valueArray);
        setSelectedOptionsInternal(data?.options || []);
      } else {
        setSelectedOptionsInternal([]);
      }
    }

    const isChanged =
      // если количество value не соответствует количеству selectedOptions
      valueArray?.length !== selectedOptions.length ||
      // или если не для каждого value есть соответствующий элемент в selectedOptions
      !valueArray.every(
        (valueItem) =>
          !!selectedOptions.find(
            (option) => valueItem === getOptionValue(option),
          ),
      );

    if (isChanged) {
      getSelectedOptions();
    }
  }, [
    value,
    isInternalSelectedItems,
    getDataByValue,
    selectedOptions,
    setSelectedOptionsInternal,
    getOptionValue,
  ]);

  // ERROR
  const currentError = useMemo(
    () =>
      getFirstError([
        {
          value: Boolean(hasError),
          message: helperText ?? '',
        },
        {
          value: Boolean(networkError),
          message: 'Ошибка при загрузке данных',
        },
      ]),
    [networkError, hasError, helperText],
  );

  // CHANGE
  const handleChangeMultiple = useCallback(
    (
      _value: TValue[],
      _options: TOption[],
      selectedOption?: TOption | null,
    ) => {
      if (multiple) {
        if (isInternalSelectedItems) {
          setSelectedOptionsInternal(_options);
        }

        onChange(_value, _options, selectedOption);
      }
    },
    [onChange, isInternalSelectedItems, multiple],
  );
  const handleChangeSingle = useCallback(
    (
      _value: TValue | null,
      _options: TOption[],
      selectedOption?: TOption | null,
    ) => {
      if (!multiple) {
        if (isInternalSelectedItems) {
          setSelectedOptionsInternal(_options);
        }

        onChange(_value, _options, selectedOption);
      }
    },
    [onChange, isInternalSelectedItems, multiple],
  );

  const searchSelectBaseProps = {
    ...restProps,
    menuWidth,
    isLoading: isLoadingExternal || isLoadingInternal,
    options: optionsServer,
    selectedOptions,
    search,
    onChangeSearch: setSearch,
    loadMore,
    isFullyLoaded: optionsCount.current >= total.current,
    hasError: currentError?.value || isSearchFieldError,
    helperText: currentError?.message,
    onOpenHandler: setIsOpenTrue,
    onCloseHandler: setIsOpenFalse,
    getOptionValue,
    getOptionLabel,
    clearSearchOnClose: true,
    isSearchFieldError,
  };

  return multiple ? (
    <SearchSelectBase
      {...searchSelectBaseProps}
      multiple
      onChange={handleChangeMultiple}
    />
  ) : (
    <SearchSelectBase
      {...searchSelectBaseProps}
      onChange={handleChangeSingle}
    />
  );
}
