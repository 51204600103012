import type { Dayjs } from 'dayjs';

import { dayjs } from '../../../external/dayjs';
import { getTimezone } from '../getTimezone/getTimezone';

/** Европейский формат записи даты */
export const euDateFormat = 'DD.MM.YYYY';

/** Европейский формат записи даты и времени */
export const euDateTimeFormat = 'DD.MM.YYYY HH:mm';

export const formatDate = (
  date?: Date | Dayjs | number | string | null | undefined,
  format: string = euDateTimeFormat,
  params?: {
    withoutTz?: boolean;
    includeTz?: boolean;
  },
  nullDisplayValue?: string,
) => {
  if (!date) {
    return nullDisplayValue ?? '-';
  }

  const { withoutTz, includeTz } = params || {};
  const showTimeZone = !withoutTz && String(date).at(-1) === 'Z';

  const tz = includeTz && showTimeZone ? ` (${getTimezone()})` : '';

  return `${dayjs(date, undefined, true).tz().format(format)}${tz}`;
};
