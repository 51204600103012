import { Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { EnrollmentStatus, HostSelect } from 'entities/Host';
import { OrgUnitSelect } from 'entities/OrgUnit';
import { UserSelect } from 'entities/User';

import { FakerExamActions } from './ui/FakerExamActions';

export const FakerExam = () => {
  const [ouId, setOuId] = useState<Nullable<string>>(null);
  const [hostId, setHostId] = useState<Nullable<string>>(null);
  const [accountId, setAccountId] = useState<Nullable<string>>(null);

  // Фильтры зависимые, поэтому при изменении OU нужно
  // сбросить остальные фильтры
  useEffect(() => {
    setHostId(null);
    setAccountId(null);
  }, [ouId]);

  return (
    <Stack flexDirection='column' gap='1rem'>
      <Typography variant='h2'>Параметры</Typography>

      <Stack gap='0.5rem'>
        <Typography variant='h4'>Выберите OU</Typography>

        <OrgUnitSelect
          label=''
          placeholder=''
          value={ouId}
          onChange={(el) => setOuId(el)}
        />
      </Stack>

      <Stack gap='0.5rem'>
        <Stack
          flexDirection='row'
          justifyContent='space-between'
          alignItems='end'
        >
          <Typography variant='h4'>Выберите ПАК</Typography>
          <Typography variant='caption' color='GrayText'>
            активные и отфильтрованы по OU
          </Typography>
        </Stack>

        <HostSelect
          label=''
          placeholder=''
          value={hostId}
          onChange={(el) => setHostId(el)}
          filters={
            ouId
              ? {
                  subtree: { in: [ouId] },
                  enrollmentStatus: { in: [EnrollmentStatus.ENROLLED] },
                }
              : undefined
          }
        />
      </Stack>

      <Stack gap='0.5rem'>
        <Stack
          flexDirection='row'
          justifyContent='space-between'
          alignItems='end'
        >
          <Typography variant='h4'>Выберите аккаунт</Typography>
          <Typography variant='caption' color='GrayText'>
            отфильтрованы по OU
          </Typography>
        </Stack>

        <UserSelect
          label=''
          placeholder=''
          value={accountId}
          onChange={(el) => setAccountId(el)}
          filters={
            ouId
              ? {
                  owner: {
                    in: [ouId],
                  },
                }
              : undefined
          }
        />
      </Stack>

      <Stack>
        <FakerExamActions
          params={{
            host: hostId || null,
            account: accountId || null,
          }}
        />
      </Stack>
    </Stack>
  );
};
