import { createNewDate, formatDate } from 'shared/lib/utils/dates';
import { euDateFormat } from 'shared/lib/utils/dates/formatDate/formatDate';

export enum TokenValidStatus {
  EXPIRING = 'expiring',
  INVALID = 'invalid',
  VALID = 'valid',
}

export const getValidTill = (val: string) => {
  const today = createNewDate();
  const date = createNewDate(val);
  const formattedDate = formatDate(date, euDateFormat);

  // если истек токен
  if (date.unix() <= today.unix()) {
    return {
      status: TokenValidStatus.INVALID,
      message: `Ваш токен истек ${formattedDate}`,
    };
  }

  // если осталось <= 30 дней до истечения токена
  if (date.subtract(30, 'day').unix() <= today.unix()) {
    return {
      status: TokenValidStatus.EXPIRING,
      message: `Ваш токен скоро закончится, до ${formattedDate}`,
    };
  }

  return {
    status: TokenValidStatus.VALID,
    message: `До ${formattedDate}`,
  };
};
