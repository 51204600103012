import { Suspense, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router';

import { authSliceActions } from 'processes/auth';

import { PageError } from 'widgets/PageError';
import { PageLoader } from 'widgets/PageLoader';

import { IndicatorClientTimeOffset, useCheckServerInfo } from 'entities/Server';

import { getBrowserStorage } from 'shared/lib/utils/storage';
import { Loader } from 'shared/ui/helpers/Loader';

import { routeConfig } from '../../../config/routes';
import { ErrorBoundary } from '../../error';
import { AuthorizedPage } from './AuthorizedPage';
import { UnauthorizedPage } from './UnauthorizedPage';

export const AppRouter = () => {
  // @ts-expect-error - Доступ к роутеру для e2e тестов
  window._nav = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const accountType = getBrowserStorage('ACCOUNT_TYPE');

  const makeBGColor = useCallback((pathname: string) => {
    return pathname.includes('login')
      ? document.body.classList.add('bg-color-help')
      : document.body.classList.remove('bg-color-help');
  }, []);

  useEffect(() => {
    makeBGColor(location.pathname);
  }, [makeBGColor, location]);

  const { status } = useCheckServerInfo();

  useEffect(() => {
    if (/\/login\/?$/.test(location.pathname)) {
      dispatch(authSliceActions.clearAccountData());
    }
  }, [location, dispatch, accountType]);

  if (status === 'loading') {
    return <Loader>Синхронизация с сервером...</Loader>;
  }
  if (status === 'error') {
    return (
      <PageError>
        При получении конфигурации сервера произошла ошибка.
      </PageError>
    );
  }

  if (status !== 'success') {
    return null;
  }

  return (
    <>
      <IndicatorClientTimeOffset />
      <Suspense fallback={<PageLoader />}>
        <Routes>
          {Object.values(routeConfig).map(
            ({ element, path, isAuth, needRules, beforeEach }) => (
              <Route
                key={path}
                path={path}
                element={
                  isAuth ? (
                    <AuthorizedPage params={{ needRules, beforeEach }}>
                      <ErrorBoundary>{element}</ErrorBoundary>
                    </AuthorizedPage>
                  ) : (
                    <UnauthorizedPage>{element}</UnauthorizedPage>
                  )
                }
              />
            ),
          )}
        </Routes>
      </Suspense>
    </>
  );
};
