import { useLazyQuery } from '@apollo/client';
import { IlFileArchive } from '@mc/react-icons/il';
import { AutocompleteRenderOptionState, Stack } from '@mui/material';
import { useCallback } from 'react';

import { InboundDocumentStatus, IndocsFilter } from 'shared/api/__generated__';
import { DocumentMappedType } from 'shared/api/userApi';
import { useErrorHandlerContext } from 'shared/lib/hooks/useErrorHandlerContext';
import { Tag } from 'shared/ui/contents/Tag';
import { Typography } from 'shared/ui/contents/Typography';
import { Checkbox } from 'shared/ui/form/checkboxes/Checkbox';
import {
  SearchSelectServer,
  SearchSelectServerProps,
} from 'shared/ui/form/selects/SearchSelectServer';
import { IconWrapper } from 'shared/ui/helpers/IconWrapper';
import { Tooltip } from 'shared/ui/helpers/Tooltip';

import { documentGetFilterOptions } from '../api/documentGetFilterOptions.gql';
import { mapDocumentGetFilterOptionsToSelectItems } from '../lib/mapDocumentGetFilterOptionsToSelectItems';
import { DocumentSelectItem } from '../types';
import cls from './DocumentSelect.module.scss';

type ExcludedSearchSelectServerProps =
  | 'getData'
  | 'getDataByValue'
  | 'getOptionLabel'
  | 'getOptionValue'
  | 'loading'
  | 'networkError'
  | 'renderOption';

export type DocumentSelectProps = (
  | Omit<
      SearchSelectServerProps<DocumentSelectItem, string, false>,
      ExcludedSearchSelectServerProps
    >
  | Omit<
      SearchSelectServerProps<DocumentSelectItem, string, true>,
      ExcludedSearchSelectServerProps
    >
) & {
  filters?: IndocsFilter;
};

export function DocumentSelect(props: DocumentSelectProps) {
  const {
    placeholder = 'Выберите документ',
    label = 'Документ',
    menuWidth,
    pageSize = 10,
    selectedOptions,
    multiple,
    filters,
    value,
    onChange,
    ...restProps
  } = props;

  const errorHandlerContext = useErrorHandlerContext();

  const [getDocs, { loading, error: networkError }] = useLazyQuery(
    documentGetFilterOptions,
    {
      context: errorHandlerContext({
        message: 'Не удалось загрузить список документов',
      }),
    },
  );

  const getData = useCallback(
    async (search: string, offset: number) => {
      try {
        const response = await getDocs({
          variables: {
            filters: {
              search,
              ...filters,
            },
            pagination: { limit: pageSize, offset },
          },
        });

        return {
          options: response.data?.indocs
            ? mapDocumentGetFilterOptionsToSelectItems(response.data.indocs)
            : [],
          total: response.data?.indocs.getFilteredList?.total || 0,
        };
      } catch (error) {
        console.error(error);

        return {
          options: [],
          total: 0,
        };
      }
    },
    [filters, getDocs, pageSize],
  );

  const getDataByValue = useCallback(
    async (_value?: DocumentSelectItem['id'][]) => {
      try {
        const response = await getDocs({
          variables: {
            filters: {
              employee: { in: _value },
              ...filters,
            },
            pagination: { limit: pageSize, offset: 0 },
          },
        });

        return {
          options: response.data?.indocs
            ? mapDocumentGetFilterOptionsToSelectItems(response.data?.indocs)
            : [],
        };
      } catch (error) {
        console.error(error);

        return { options: [] };
      }
    },
    [filters, getDocs, pageSize],
  );

  const getOptionValue = useCallback(({ id }: DocumentSelectItem) => id, []);
  const getOptionLabel = useCallback(
    (option: DocumentSelectItem) => option.name || '',
    [],
  );

  const renderOption = useCallback(
    (
      optionProps: React.HTMLAttributes<HTMLLIElement>,
      option: DocumentSelectItem,
      state: AutocompleteRenderOptionState,
    ) => {
      const { selected, index } = state;
      const { id, type, status, issuedAt, expiresAt } = option;

      const key = `listItem-${index}-${id}`;

      return (
        <li {...optionProps} key={key}>
          {multiple && (
            <Checkbox
              className={cls.Checkbox}
              size='medium'
              checked={selected}
              id={key}
            />
          )}

          <Stack gap='4px'>
            <Stack
              gap='8px'
              flexDirection='row'
              alignItems='center'
              flexWrap='wrap'
            >
              <Typography variant='t1'>{getOptionLabel(option)}</Typography>

              {status === InboundDocumentStatus.ARCHIVED && (
                <Tooltip title='В архиве'>
                  <Tag
                    color='grey'
                    icon
                    label={<IconWrapper Svg={IlFileArchive} />}
                  />
                </Tooltip>
              )}
            </Stack>

            <Stack
              rowGap='2px'
              columnGap='16px'
              flexDirection='row'
              flexWrap='wrap'
              color='var(--color-monochrome-grey-400)'
            >
              <Typography variant='t3'>{DocumentMappedType[type]}</Typography>

              <Typography variant='t3'>
                {`${issuedAt} — ${expiresAt}`}
              </Typography>
            </Stack>
          </Stack>
        </li>
      );
    },
    [getOptionLabel, multiple],
  );

  const searchSelectServerProps = {
    ...restProps,
    label,
    menuWidth,
    pageSize,
    placeholder,
    isLoading: loading,
    renderOption,
    getData,
    getDataByValue: selectedOptions ? undefined : getDataByValue,
    selectedOptions,
    networkError,
    getOptionLabel,
    getOptionValue,
  };

  return multiple ? (
    <SearchSelectServer
      {...searchSelectServerProps}
      multiple
      onChange={onChange}
      value={value}
    />
  ) : (
    <SearchSelectServer
      {...searchSelectServerProps}
      onChange={onChange}
      value={value}
    />
  );
}
