import { gql } from 'shared/api/__generated__';

export const HOST_GET_FILTER_OPTIONS = gql(/* GraphQL */ `
  query HOST_GET_FILTER_OPTIONS(
    $filters: HostFilters!
    $pagination: Pagination!
  ) {
    hosts {
      getFilteredList(filters: $filters, pagination: $pagination) {
        ...FragmentHostFilterOptions
      }
    }
  }
`);
