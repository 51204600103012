import { useMutation } from '@apollo/client';
import { IlFileSignature } from '@mc/react-icons/il';
import { Box, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  DocumentsSubscriber,
  getCertificate,
  getIsCertificateError,
  getIsSigning,
  getIsSigningError,
  signingActions,
} from 'processes/documentSigning';
import { getIsConnectionError } from 'processes/documentSigning/model/selectors/getIsConnectionError';
import { useNotifications } from 'processes/notifications/ui/Notifications.context';

import { CertificateSelectModal, parseSubject } from 'features/Signing';

import { SIGNING_ASSERT_CERTIFICATE } from 'shared/api/signingApi';
import { getCertificate as getCertificateFromSystem } from 'shared/lib/external/cryptoPro';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { useErrorHandlerContext } from 'shared/lib/hooks/useErrorHandlerContext';
import {
  getBrowserStorage,
  removeBrowserStorage,
} from 'shared/lib/utils/storage';
import { IconButton } from 'shared/ui/buttons/IconButton';
import { IconWrapper } from 'shared/ui/helpers/IconWrapper';
import { Loader } from 'shared/ui/helpers/Loader';

import cls from './NavbarSigningControl.module.scss';
import { SigningDropdown } from './SigningDropdown';

export const NavbarSigningControl = () => {
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLButtonElement>>(null);
  const ref = useRef<Nullable<HTMLDivElement>>(null);

  const [loading, setLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { certificateId } = useSelector(getCertificate);
  const isCertificateError = useSelector(getIsCertificateError);
  const isSigningError = useSelector(getIsSigningError);
  const isSigning = useSelector(getIsSigning);
  const isConnectionError = useSelector(getIsConnectionError);

  const usedCert = getBrowserStorage('CERTIFICATE');

  const isCriticalError = isCertificateError && isSigningError;

  const isRedIndicator =
    isCertificateError || isSigningError || isConnectionError;

  const dispatch = useAppDispatch();
  const notificationsActions = useNotifications();
  const errorHandlerContext = useErrorHandlerContext();

  const [assertCertificate] = useMutation(SIGNING_ASSERT_CERTIFICATE, {
    context: errorHandlerContext({
      message: 'Не удалось запустить цикл подписания документов',
    }),
  });

  const autoSelectCertificate = useCallback(async () => {
    setLoading(true);
    dispatch(signingActions.setStatus('loading'));
    try {
      // получаем последний использованный сертификат из системы
      const cert = await getCertificateFromSystem(usedCert!);
      // отправляем сертификат на сервер
      const assertedResult = await assertCertificate({
        variables: {
          input: {
            serial: cert.thumbprint,
            issuer: cert.issuerName,
            subject: cert.subjectName,
            issuedAt: dayjs(cert.validFrom).toDate(),
            expiresAt: dayjs(cert.validTo).toDate(),
          },
        },
      });

      if (assertedResult.errors) {
        dispatch(signingActions.setStatus('error'));
        notificationsActions.showErrorNotification({
          title: 'Ошибка Сертификата',
          message: 'Не удалось запустить цикл подписания документов',
        });
        return;
      }

      // данные о сертификате с сервера
      const assertedCert =
        assertedResult.data?.signing.certificates.assert.certificate;

      // выставляем сертификат в стор и запускаем цикл подписи
      if (assertedCert) {
        const subject = parseSubject(cert.subjectName);
        dispatch(
          signingActions.setCertificate({
            serial: cert.thumbprint,
            certificateId: assertedCert.id,
            orgUnitName: subject.CN,
            subject,
            issuedAt: cert.validFrom,
            expiresAt: cert.validTo,
          }),
        );
        dispatch(signingActions.setCertificateError(false));
        dispatch(signingActions.setIsSigningError(false));
        dispatch(signingActions.tickCounter());
        dispatch(signingActions.setStatus('active'));
      } else {
        dispatch(signingActions.setStatus('notSet'));
      }
    } catch (err) {
      dispatch(signingActions.setStatus('error'));
      console.error(
        'Не удалось воспользоваться ранее использованным сертификатом',
        err,
      );
      dispatch(signingActions.setCertificateError(true));
      removeBrowserStorage('CERTIFICATE');

      notificationsActions.showErrorNotification({
        title: 'Ошибка Сертификата',
        message: 'Необходимо выбрать сертификат',
      });
    } finally {
      setLoading(false);
    }
  }, [assertCertificate, dispatch, usedCert, notificationsActions]);

  useEffect(() => {
    if (usedCert) autoSelectCertificate();
  }, []);

  useEffect(() => {
    if (!certificateId && !usedCert) {
      // dispatch(signingActions.setStatus('error'));
      dispatch(signingActions.setCertificateError(true));
    }
  }, [certificateId, dispatch, usedCert]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        !!anchorEl
      ) {
        setAnchorEl(null);
      }
    };

    const handlePressDown = (event: KeyboardEvent) => {
      if (ref.current && !!anchorEl && event.code === 'Escape') {
        setAnchorEl(null);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('keydown', handlePressDown);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keydown', handlePressDown);
    };
  }, [anchorEl]);

  return (
    <Stack
      className={isCriticalError ? cls.RootCriticalError : cls.Root}
      ref={ref}
    >
      <Stack className={cls.AnchorContainer}>
        <IconButton
          onClick={(e) => setAnchorEl(anchorEl ? null : e.currentTarget)}
        >
          {loading ? (
            <Loader className={cls.LoaderIndicator} />
          ) : (
            <IconWrapper Svg={IlFileSignature} className={cls.AnchorIcon} />
          )}
        </IconButton>
      </Stack>

      {/* индикатор статуса */}
      {(isSigning || isRedIndicator) && !loading && (
        <Box
          className={isRedIndicator ? cls.RedIndicator : cls.YellowIndicator}
        />
      )}

      {anchorEl && (
        <SigningDropdown
          setAnchorEl={setAnchorEl}
          setIsModalOpen={setIsModalOpen}
        />
      )}

      {isModalOpen && (
        <CertificateSelectModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onCertificateChosen={({ success }) =>
            success && setIsModalOpen(false)
          }
        />
      )}

      {!loading && certificateId && <DocumentsSubscriber />}
    </Stack>
  );
};
