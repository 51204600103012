import { Stack } from '@mui/material';

import { OrgUnitType } from 'shared/api/__generated__';
import { Typography } from 'shared/ui/contents/Typography';

import { OrgUnitMappedType } from '../../../utils';
import cls from './OrgUnitSelect.module.scss';

interface OrgUnitInfoContentProps {
  inn?: string;
  kpp?: string;
  ogrnip?: string;
  type: OrgUnitType;
}

export const OrgUnitInfoContent = (props: OrgUnitInfoContentProps) => {
  const { inn, kpp, ogrnip, type } = props;
  return (
    <Stack className={cls.ItemInfo}>
      {type !== OrgUnitType.INDIVIDUAL_ENTREPRENEUR && (
        <Typography variant='t3'>{OrgUnitMappedType[type]}</Typography>
      )}
      {inn && <Typography variant='t3'>ИНН&nbsp;{inn}</Typography>}
      {kpp && <Typography variant='t3'>КПП&nbsp;{kpp}</Typography>}
      {ogrnip && <Typography variant='t3'>ОГРНИП&nbsp;{ogrnip}</Typography>}
    </Stack>
  );
};
